import React from 'react';
import { logo2 } from './assets';

const Intro = () => {
  return (
    <div className='h-screen w-screen flex flex-col bg-white justify-center items-center'>
      {/* <div>iTrood Shop</div> */}
      <img src={logo2} className='w-[300px]' alt='iTrood Shop logo' />
    </div>
  )
};

export default Intro;
