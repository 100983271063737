import { useState, useEffect, useContext } from "react";
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";
import { doc, setDoc, serverTimestamp } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useNavigate, useLocation } from "react-router-dom";
import { auth, db, storage } from "../firebase";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import PropagateLoader from 'react-spinners/esm/PropagateLoader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthContext } from "../context/AuthContext";
import CryptoJS from "crypto-js";

const secretKey = "8b364ea665d053603748ccdf2c181573adcac5c3f9a0f34860ac23a7f15f7c06";

const CreateUser = ({ inputs, title }) => {
  const { currentUser } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [file, setFile] = useState(null);
  const [data, setData] = useState({});
  const [progress, setProgress] = useState(null);
  const [isFormValid, setIsFormValid] = useState(false); 
  const navigate = useNavigate();
  const location = useLocation();

  const loggedInUserId = currentUser.uid;

  // Automatically set user status based on query parameter
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const userType = queryParams.get("type") || "";
    setData((prev) => ({ ...prev, status: userType }));
  }, [location]);

  // Validate form to enable/disable submit button
  useEffect(() => {
    const isValid =
      file &&
      progress === 100 &&
      data.status &&
      inputs.every((input) => data[input.id]);

    setIsFormValid(isValid);
  }, [file, progress, data, inputs]);

  useEffect(() => {
    const uploadFile = () => {
      const name = new Date().getTime() + file.name;
      const storageRef = ref(storage, `users/${name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setProgress(progress);
        },
        (error) => {
          console.log(error);
          toast.error("Error occurred while uploading the image. Please try again.");
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setData((prev) => ({ ...prev, img: downloadURL }));
            toast.info("Image uploaded successfully.");
          });
        }
      );
    };
    file && uploadFile();
  }, [file]);

  const handleInput = (e) => {
    const id = e.target.id;
    let value = e.target.value;

    if (id === 'username') {
      value = value.toLowerCase().replace(/\s+/g, '');
    }

    setData({ ...data, [id]: value });
  };

  const handleAdd = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");
  
    try {
      // Store the current agent's credentials (to log back in)
      const storedEmail = localStorage.getItem("email");
      const storedEncryptedPassword = localStorage.getItem("password");
  
      if (!storedEmail || !storedEncryptedPassword) {
        throw new Error("Agent credentials not found. Please login again.");
      }
  
      // Decrypt the password
      const bytes = CryptoJS.AES.decrypt(storedEncryptedPassword, secretKey);
      const agentPassword = bytes.toString(CryptoJS.enc.Utf8);
  
      // Create the new user
      const res = await createUserWithEmailAndPassword(auth, data.email, data.password);
  
      let subscription = "";
      switch (data.status) {
        case "distributor":
          subscription = "notPaid";
          break;
        default:
          subscription = "non-subscriber";
          break;
      }
  
      // Create the user document in Firestore
      await setDoc(doc(db, "users", res.user.uid), {
        ...data,
        subscription,
        timeStamp: serverTimestamp(),
        origin: "users",
        registeredAgent: loggedInUserId,
      });
  
      // Sign out the newly created user
      await auth.signOut();
  
      // Re-authenticate the agent (you)
      await signInWithEmailAndPassword(auth, storedEmail, agentPassword);
  
      toast.success("User created successfully!");
  
    } catch (err) {
      console.error(err);
      toast.error(`Error occurred while creating a user: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex">
      <div className="flex flex-col w-full">
        <div className="p-4">
          <div className="bg-gray-200 p-4 mb-4">
            <h1 className="text-lg font-semibold text-gray-700">{title}</h1>
          </div>
          <div className="flex-1">
            <form onSubmit={handleAdd} className="grid grid-cols-2 gap-4 justify-center">
              <div className="w-full">
                <label htmlFor="file" className="flex-1 text-center">
                  <img
                    src={
                      file
                        ? URL.createObjectURL(file)
                        : "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
                    }
                    alt=""
                    className="cursor-pointer w-32 h-32 rounded-full object-cover mx-auto"
                  />
                </label>
                <input
                  type="file"
                  id="file"
                  onChange={(e) => setFile(e.target.files[0])}
                  className="hidden"
                />
              </div>
              {inputs.map((input) => (
                <div className="w-full" key={input.id}>
                  <label className="flex items-center gap-2">
                    {input.label}
                  </label>
                  <input
                    id={input.id}
                    type={input.type}
                    placeholder={input.placeholder}
                    required
                    onChange={handleInput}
                    value={data[input.id] || ""}
                    className="w-full px-2 py-1 border-b border-gray-300 rounded scrollbar-thin border-0 resize-none shadow-md"
                  />
                </div>
              ))}
              {/* Status dropdown */}
              <div className="w-full">
                <label className="flex items-center gap-2">
                  Status:
                </label>
                <select
                  id="status"
                  value={data.status || ""}
                  onChange={handleInput}
                  className="w-full px-2 py-1 border-b border-gray-300 rounded scrollbar-thin border-0 resize-none shadow-md"
                  required
                >
                  <option value="">Select User Type</option>
                  <option value="user">User</option>
                  <option value="library">Library</option>
                </select>
              </div>
              {loading ? (
                <div className="w-full bg-zinc-100 py-2 rounded-md">
                  <PropagateLoader color="#3693d6" />
                </div>
              ) : (
                <button
                  type="submit"
                  disabled={!isFormValid}
                  className={`w-full text-white font-bold py-2 rounded-md cursor-pointer ${isFormValid ? "bg-teal-500" : "bg-zinc-300"}`}
                >
                  Register
                </button>
              )}
            </form>
            {progress !== null && (
              <progress value={progress} max="100" className="w-full mt-4"></progress>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateUser;