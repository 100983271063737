import React, { useContext, useState, useEffect } from 'react';
import { DarkModeContext } from '../context/darkModeContext';
import { MdVerified, MdOutlineVerified } from "react-icons/md";
import { AiOutlineShareAlt } from "react-icons/ai";
import { collection, doc, setDoc, getDocs, updateDoc, query, where } from 'firebase/firestore';
import { db } from '../firebase';
import { Link } from 'react-router-dom';
import { useUserData } from '../lib/userData';

const Profile = () => {
  const { darkMode } = useContext(DarkModeContext);
  const userData = useUserData();
  const [isExpanded, setIsExpanded] = useState(false);
  const [referralID, setReferralID] = useState(null);

  useEffect(() => {
    const fetchReferralID = async () => {
      try {
        const referralsCollection = collection(db, 'referrals');
        const querySnapshot = await getDocs(query(referralsCollection, where('userId', '==', userData?.id)));
        if (!querySnapshot.empty) {
          setReferralID(querySnapshot.docs[0].id);
        } else {
          const newReferralID = String((await getDocs(referralsCollection)).size + 1).padStart(7, '0');
          const referralDocRef = doc(referralsCollection, newReferralID);
          await setDoc(referralDocRef, {
            id: newReferralID,
            userId: userData?.id,
            userName: userData?.displayName,
            earnings: '0.00',
            currency: 'TZS',
            users: []
          });
          await updateDoc(doc(db, 'users', userData.id), { referralID: newReferralID });
          setReferralID(newReferralID);
        }
      } catch (error) {
        console.error('Error fetching or creating referral ID:', error);
      }
    };
    
    fetchReferralID();
  }, [userData?.id, userData?.displayName]);

  const handleShareClick = async () => {
    if (referralID) {
      const shareData = {
        title: 'Join iTrood and Earn with Referrals!',
        text: `Hi, I'm ${userData?.displayName}. Join iTrood using my referral link and enjoy our amazing content!`,
        url: `https://app.itrood.com/register?referral=${referralID}`,
      };

      try {
        await navigator.share(shareData);
      } catch (error) {
        console.error('Error sharing:', error);
      }
    }
  };

  const toggleExpand = () => setIsExpanded(!isExpanded);

  return (
    <div className='h-full flex max-sm:flex-col-reverse gap-2 overflow-y-auto scrollbar-hide'>
      {/* User Profile */}
      <div className={`w-[30%] max-sm:w-full h-full gap-2 p-2 flex flex-col items-center rounded-2xl border ${darkMode ? "border shadow-[0px_0px_4px_2px_rgba(200,_300,_400,_0.50)] hover:shadow-[0px_0px_4px_2px_rgba(0,_179,_255,_0.23)]" : "light-mode bg-white"}`}>
        <div className='flex gap-2 w-full h-full flex-col items-center justify-between'>
          <div className='h-[40%] flex flex-col w-full items-center justify-center'>
            <div className='h-[80%] w-full flex items-center justify-center'>
              <img src={userData?.img} className='h-[100px] w-[100px] justify-center items-center border rounded-full shrink-0 bg-zinc-300 object-cover' />
            </div>
            <Link to={`/edit-profile`} className='text-center text-blue-500 bg-white cursor-pointer border-spacing-4 rounded-xl border-blue-500 hover:bg-blue-500 hover:text-white hover:font-bold border-2 px-2 w-fit mt-[-30px]'>Edit</Link>
            <div className='gap-1 flex justify-center items-center'>
              <div className='h-[20%] flex w-full'>{userData?.displayName}</div>
              {userData?.verification === "verified" && (
                <div className='text-[13px] font-semibold text-center mt-[20px]'>{darkMode ? <MdOutlineVerified /> : <MdVerified color='blue' />}</div>
              )}
            </div>
            <div className='text-[12px] font-semibold text-center text-gray-300'>{userData?.status}</div>
            <div className='text-center'>@{userData?.username || 'username'}</div>
          </div>
          <div className='flex flex-col gap-2 w-full h-[65%]'>
            <div className={`flex flex-col rounded-xl border h-auto w-full p-2 ${darkMode ? "border shadow-[0px_0px_4px_2px_rgba(200,_300,_400,_0.50)] hover:shadow-[0px_0px_4px_2px_rgba(0,_179,_255,_0.23)]" : "light-mode bg-zinc-100"}`}>
              <div className='text-[12px] font-semibold text-slate-400'>Biography</div>
              <div className='text-[14px]'>
                {userData?.bio ? (
                  <>
                    <div className={`overflow-hidden ${!isExpanded && 'line-clamp-2'}`}>
                      {userData?.bio}
                    </div>
                    {userData?.bio.split(' ').length > 20 && (
                      <button onClick={toggleExpand} className="text-blue-500 mt-1">
                        {isExpanded ? 'View less' : 'View more'}
                      </button>
                    )}
                  </>
                ) : (
                  'No Bio'
                )}
              </div>
            </div>
            <div className={`flex flex-col rounded-xl border h-[50%] w-full p-2 ${darkMode ? "border shadow-[0px_0px_4px_2px_rgba(200,_300,_400,_0.50)] hover:shadow-[0px_0px_4px_2px_rgba(0,_179,_255,_0.23)]" : "light-mode bg-zinc-100"}`}>
              <div>{userData?.email || 'email'}</div>
              <div>{userData?.phone || 'phone'}</div>
              <div>{userData?.address || 'address'}</div>
              <div>{userData?.country || 'country'}</div>
            </div>
            {/* <div className={`flex items-center justify-between rounded-xl border h-[20%] w-full p-2 ${darkMode ? "border shadow-[0px_0px_4px_2px_rgba(200,_300,_400,_0.50)] hover:shadow-[0px_0px_4px_2px_rgba(0,_179,_255,_0.23)]" : "light-mode bg-zinc-100"}`}>
              <div>{userData?.socials || 'Socials'}</div>
              <div
                onClick={handleShareClick}
                className='text-center flex gap-2 rounded-md justify-center items-center p-[2px] px-2 w-[120px] h-fit max-sm:w-[100px] max-sm:text-[12px] cursor-pointer bg-green-500 shadow-sm font-semibold text-white'
              >
                <div>Share</div>
                <AiOutlineShareAlt size={24} />
              </div>
            </div> */}
            {referralID ? (
              <button onClick={handleShareClick} className="flex justify-center items-center mt-2 px-3 py-2 border rounded-lg bg-teal-500 text-white shadow-md hover:bg-teal-600">
                <AiOutlineShareAlt className="mr-2" /> Share Referral Link
              </button>
            ) : (
              <button onClick={handleShareClick} className="flex justify-center items-center mt-2 px-3 py-2 border rounded-lg bg-blue-500 text-white shadow-md hover:bg-blue-600">
                Generate Referral ID
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;