import { auth, db } from "./firebase"; // Import Firebase auth and db
import { getFirestore, collection, doc, onSnapshot } from "firebase/firestore";

// Function to get current user data and subscribe to real-time updates
export const getCurrentUserData = (callback) => {
    const user = auth.currentUser;
  
    if (user) {
      // User is signed in
      try {
        // Subscribe to real-time updates for the user document with UID as the document ID
        const unsubscribe = onSnapshot(doc(collection(db, "users"), user.uid), (snapshot) => {
          if (snapshot.exists()) {
            // User document found, invoke the callback function with its data
            callback(snapshot.data());
          } else {
            // User document does not exist
            console.error("User document not found in Firestore");
            callback(null);
          }
        });
  
        // Return the unsubscribe function to stop listening to updates when needed
        return unsubscribe;
      } catch (error) {
        console.error("Error subscribing to user data changes in Firestore:", error);
        return null;
      }
    } else {
      // No user is signed in
      return null;
    }
  };
