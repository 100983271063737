import { useContext, useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { userColumns } from "./datatablesource";
import { Link } from "react-router-dom";
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  onSnapshot,
  setDoc,
} from "firebase/firestore";
import { db } from "../firebase";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DarkModeContext } from '../context/darkModeContext';

const Datatable = () => {
  const { darkMode } = useContext(DarkModeContext);
  const [data, setData] = useState([]);

  useEffect(() => {
    const unsub = onSnapshot(
      collection(db, "users"),
      (snapShot) => {
        let list = [];
        snapShot.docs.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        setData(list);
      },
      (error) => {
        console.log(error);
      }
    );

    return () => {
      unsub();
    };
  }, []);

  const handleDelete = async (id) => {
    // Show confirmation alert
    confirmAlert({
      title: 'Confirm Deletion',
      message: 'Are you sure you want to delete this user?',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            try {
              const userDocRef = doc(db, "users", id);
              const userDocSnapshot = await getDoc(userDocRef);
              
              if (userDocSnapshot.exists()) {
                // Move the user document to the Recycle Bin collection
                await setDoc(doc(db, "recycleBin", id), userDocSnapshot.data());
  
                // Delete the user document from the users collection
                await deleteDoc(userDocRef);
  
                // Update the UI
                setData(data.filter((item) => item.id !== id));
  
                // Show success message
                toast.success("User moved to Recycle Bin successfully!"); 
              } else {
                toast.error("User not found."); 
              }
            } catch (err) {
              console.log(err);
              toast.error("Failed to move user to Recycle Bin."); 
            }
          }
        },
        {
          label: 'No',
          onClick: () => {} 
        }
      ]
    });
  };

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 150,
      headerClassName: `${darkMode ? "text-white" : "light-header"}`,
      renderCell: (params) => {
        return (
          <div className={`flex items-center gap-4 ${darkMode ? "dark-mode-class" : "light-mode-class"}`}>
            <Link to={`/users/user/${params.row.id}`} className={`text-blue-500 hover:text-green-500 border border-dotted border-blue-500 hover:border-green-500 rounded px-2 py-1`}>
              View
            </Link>
            <div
              className={`border border-dotted border-red-500 hover:bg-red-500 hover:text-white ${darkMode ? "text-red-500 bg-transparent" : "text-red-500"} rounded px-2 py-1 cursor-pointer`}
              onClick={() => handleDelete(params.row.id)}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div className={`h-full p-5 ${darkMode ? "dark-mode-class" : "light-mode-class"}`}>
      <div className="flex items-center justify-between mb-4">
        <span className={`text-${darkMode ? "white" : "gray-600"} text-lg font-semibold`}>Add New User</span>
        <Link to="/create/user" className={`link text-${darkMode ? "green-600" : "green-600"} border border-${darkMode ? "green-600" : "gray-600"} rounded px-3 py-1 cursor-pointer`}>
          Add New
        </Link>
      </div>
      <DataGrid
        rows={data}
        columns={userColumns(darkMode).concat(actionColumn)}
        pageSize={20}
        rowsPerPageOptions={[20]}
        checkboxSelection
        className={`rounded scrollbar-thin border-0 resize-none shadow-md ${darkMode ? "dark-mode-class" : "light-mode-class"}`}
      />
    </div>
  );
};

export default Datatable;