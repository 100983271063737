import React, { useState, useEffect } from "react";
import { logo2, bgImage } from '../assets';
import TextTransition, { presets } from 'react-text-transition';
import { useUserData } from '../lib/userData';
import { db } from '../firebase';
import { doc, setDoc, getDoc, updateDoc, collection, getDocs, serverTimestamp } from "firebase/firestore";
import { useModal } from "../context/ModalContext";
import { Dialog, DialogPanel, DialogTitle } from '@headlessui/react';
import { toast, ToastContainer } from 'react-toastify';

// Modal Component
const Modal = ({ show, children, handleClose }) => {
  const showHideClassName = show ? "modal display-block" : "modal display-none";
  return (
    <div className={showHideClassName} style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: 0 }}>
      <section className="modal-main">
        {React.cloneElement(children, { handleClose })}
      </section>
    </div>
  );
};

const TEXTS = ['Unlimited Movies!', 'Watch Anywhere!', 'Cancel Anytime!'];

const PayWallContent = ({ handleClose }) => {
  const [loadingButtonIndex, setLoadingButtonIndex] = useState(null);
  const [eligibleForFreeTrial, setEligibleForFreeTrial] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [voucherCode, setVoucherCode] = useState('');
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [cost, setCost] = useState(0);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const userData = localStorage.getItem('selectedUser');
  const [index, setIndex] = useState(0);
  const lipaNumber = '57886370';
  const phoneNumber = '+255760808652';
  const user = JSON.parse(userData);
  const userId = user?.id;

  useEffect(() => {
    const intervalId = setInterval(
      () => setIndex((index) => index + 1),
      3000,
    );
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const checkFreeTrialEligibility = async () => {
      if (!userId) return;

      const subscriptionRef = doc(db, 'premiumSubscriptions', userId);

      try {
        const subscriptionDoc = await getDoc(subscriptionRef);
        const subscriptionData = subscriptionDoc.data();

        if (subscriptionData && subscriptionData.plan === 'Free Trial') {
          setEligibleForFreeTrial(false);
          return;
        }

        const historyCollectionRef = collection(subscriptionRef, 'history');
        const historySnapshot = await getDocs(historyCollectionRef);
        let usedFreeTrial = false;

        historySnapshot.forEach((doc) => {
          if (doc.data().plan === 'Free Trial') {
            usedFreeTrial = true;
          }
        });

        setEligibleForFreeTrial(!usedFreeTrial);

      } catch (error) {
        console.error('Error checking free trial eligibility:', error);
      }
    };

    checkFreeTrialEligibility();
  }, [userData]);

  const handleSubscribe = (plan, index) => {
    setSelectedPlan(plan);
    if (plan === 'Free Trial') {
      subscribeToPlan(plan);
    } else {
      setCost(getPlanCost(plan)); // Set cost based on selected plan
      setIsDialogOpen(true);
    }
  };

  const getPlanCost = (plan) => {
    const plans = {
      'Free Trial': 0,
      'Weekly Plan': 5000,
      'Monthly Plan': 10000,
      '3 Months Plan': 30000,
      '6 Months Plan': 60000,
      'Yearly Plan': 120000
    };
    return plans[plan] || 0;
  };

  const handleVoucherValidation = async () => {
    if (!voucherCode) {
      setError('Voucher code is required');
      return;
    }

    try {
      const cost = getPlanCost(selectedPlan); // Get cost for selected plan
      const docData = await validateVoucher(voucherCode, cost);
      if (docData) {
        await subscribeToPlan(selectedPlan);
      }
    } catch (error) {
      setError(error.message);
    }
  };

  const validateVoucher = async (code, cost) => {
    const docRef = doc(db, 'prePaidCodes', code);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const docData = docSnap.data();
      const { code: storedCode, expire, value, currency, used } = docData;

      if (storedCode === code) {
        if (expire === "") {
          if (!used) {
            if (parseInt(value) === cost) {
              if (currency === 'TZS') {
                return docData; // Valid voucher
              } else {
                throw new Error("Currency mismatch");
              }
            } else {
              throw new Error("Voucher value does not match the cost");
            }
          } else {
            throw new Error("Voucher already used");
          }
        } else {
          throw new Error("Voucher expired");
        }
      } else {
        throw new Error("Invalid voucher code");
      }
    } else {
      throw new Error("Voucher code not found");
    }
  };

  const subscribeToPlan = async (plan) => {
    setLoadingButtonIndex(0);

    const plans = {
      'Free Trial': { cost: 0, duration: 7 * 24 * 60 * 60 * 1000 }, // 7 days in milliseconds
      'Weekly Plan': { cost: 5000, duration: 7 * 24 * 60 * 60 * 1000 }, // 7 days for Weekly Plan
      'Monthly Plan': { cost: 10000, duration: 30 * 24 * 60 * 60 * 1000 }, // 30 days in milliseconds
      '3 Months Plan': { cost: 30000, duration: 90 * 24 * 60 * 60 * 1000 }, // 90 days in milliseconds
      '6 Months Plan': { cost: 60000, duration: 180 * 24 * 60 * 60 * 1000 }, // 180 days in milliseconds
      'Yearly Plan': { cost: 120000, duration: 365 * 24 * 60 * 60 * 1000 }, // 365 days in milliseconds
    };

    const selectedPlanData = plans[plan];

    if (!userId) {
      console.error('User not selected');
      setLoadingButtonIndex(null);
      setIsDialogOpen(false);
      return;
    }

    const subscriptionRef = doc(db, 'premiumSubscriptions', userId);
    const subscriptionDoc = await getDoc(subscriptionRef);

    try {
      const now = new Date();
      const newExpirationDate = new Date(now.getTime() + selectedPlanData.duration);

      if (subscriptionDoc.exists()) {
        const subscriptionData = subscriptionDoc.data();
        const expireDateStr = subscriptionData.expireDate.toDate().toISOString();
        const historyRef = doc(collection(subscriptionRef, 'history'), expireDateStr);
        await setDoc(historyRef, {
          ...subscriptionData,
          expireDate: subscriptionData.expireDate.toDate(),
        });

        await setDoc(subscriptionRef, {
          ...subscriptionData,
          plan: plan,
          cost: selectedPlanData.cost,
          currency: "TZS",
          expire: false,
          expireDate: newExpirationDate,
          paymentGateway: "Pre-Paid Code",
          subscribed: new Date(),
        }, { merge: true });
      } else {
        await setDoc(subscriptionRef, {
          id: userId,
          userId: userId,
          plan: plan,
          cost: selectedPlanData.cost,
          currency: "TZS",
          expire: false,
          expireDate: newExpirationDate,
          paymentGateway: "Pre-Paid Code",
          subscribed: new Date(),
        });
      }

      // Update the voucher and report earnings
      const transactionID = await updateVoucherAfterUse(voucherCode, userId);
      await reportEarnings(transactionID, selectedPlanData.cost);

      setLoadingButtonIndex(null);
      setIsDialogOpen(false);
      handleClose();
    } catch (error) {
      console.error('Subscription failed', error);
      setLoadingButtonIndex(null);
      setIsDialogOpen(false);
    }
  };

  const updateVoucherAfterUse = async (code, userId) => {
    const docRef = doc(db, 'prePaidCodes', code);

    try {
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const docData = docSnap.data();
        const { transactionID } = docData;

        await setDoc(docRef, {
          used: 'true',
          expire: 'yes',
          expireDate: serverTimestamp(),
          user: userId,
          content: 'premium-subscription',
          creditedTo: [{ 'company': 'iTrood, Inc', earned: '75%' }, { 'agent' : user.registeredAgent, earned: '25%' }],
        }, { merge: true });

        console.log('Voucher updated successfully');
        return transactionID;
      } else {
        throw new Error("Voucher code not found");
      }
    } catch (error) {
      console.error('Error updating voucher:', error);
      throw error;
    }
  };

  const reportEarnings = async (transactionID, cost) => {
    const iTroodEarnings = (cost * 0.75).toFixed(2); // 75% for iTrood, Inc
    const agentEarnings = (cost * 0.25).toFixed(2); // 25% for the agent
  
    const earningsRef = doc(db, 'earnings', transactionID);
    const earningsData = {
      buyer: userId,
      currency: 'TZS',
      iTroodEarnings: iTroodEarnings,
      agentEarnings: agentEarnings,
      id: transactionID,
      paymentMethod: 'Pre-Paid Code',
      creditedTo: [
        { company: 'iTrood, Inc', earned: '75%' },
        { agent: user.registeredAgent, earned: '25%' }
      ],
      value: cost,
    };
  
    try {
      await setDoc(earningsRef, earningsData);
      console.log('Earnings reported successfully');
    } catch (error) {
      console.error('Error reporting earnings:', error);
    }
  
    // Update agent's earnings balance
    if (user.registeredAgent) {
      const agentRef = doc(db, 'users', user.registeredAgent);
      const agentDoc = await getDoc(agentRef);
  
      if (agentDoc.exists()) {
        const agentData = agentDoc.data();
        const currentTotalRevenue = parseFloat(agentData.earningsTotalRevenue) || 0;
        const currentEarningBalance = parseFloat(agentData.earningBalance) || 0;
        const newTotalRevenue = currentTotalRevenue + parseFloat(agentEarnings);
        const newEarningBalance = currentEarningBalance + parseFloat(agentEarnings);
  
        await updateDoc(agentRef, {
          earningsTotalRevenue: newTotalRevenue.toFixed(2),
          earningBalance: newEarningBalance.toFixed(2),
        });
  
        console.log('Agent earnings updated successfully');
      } else {
        console.error('Agent document not found');
      }
    } else {
      console.error('Agent is not defined or not registered');
    }
  };

  const handleCopyLipaNumber = () => {
    navigator.clipboard.writeText(lipaNumber).then(() => {
      toast.success('Lipa number copied to clipboard!');
    }).catch((err) => {
      console.error('Failed to copy:', err);
    });
  };

  return (
    <div className="relative flex flex-col items-center h-screen overflow-hidden scrollbar-hide">
      <div className="absolute inset-0 bg-cover bg-center bg-fixed" style={{ backgroundImage: `url(${bgImage})` }}>
        <div className="absolute z-[1] inset-0 bg-gradient-to-b from-black via-transparent to-black opacity-75"></div>
      </div>
      <button
        className="z-[10] text-white bg-red-600 w-10 h-10 cursor-pointer rounded-full p-2 m-2"
        onClick={handleClose}
      >
        X
      </button>
      <div className="relative z-10 flex flex-col items-center justify-center h-full w-full p-4 overflow-y-auto scrollbar-hide mb-[60px] pb-[100px]">
        <img className="w-[200px] max-sm:mt-[460px]" alt="iTrood Logo" src={logo2} />
        <h1 className="text-4xl font-bold text-white">Choose Your Plan</h1>
        <div className="text-center mb-6 text-white">Stream and chill with us</div>
        <div className="w-full items-center justify-center flex flex-wrap gap-6 text-white">
          {eligibleForFreeTrial && (
            <div className="bg-gray-800 p-6 rounded-lg shadow-lg hover:bg-gray-700 transition duration-300">
              <h2 className="text-2xl font-bold mb-2">Free Trial</h2>
              <p className="text-xl mb-4">7 Days <strong className='text-sm text-slate-300'>TZS 0.00</strong></p>
              <button
                className={`cursor-pointer bg-red-600 hover:bg-red-700 text-white py-2 px-4 rounded-full transition duration-300 ${loadingButtonIndex === 0 ? 'cursor-not-allowed' : ''}`}
                onClick={() => handleSubscribe('Free Trial', 0)}
                disabled={loadingButtonIndex === 0}
              >
                {loadingButtonIndex === 0 ? 'Subscribing...' : 'Subscribe'}
              </button>
            </div>
          )}
          {['Weekly Plan', 'Monthly Plan', '3 Months Plan', '6 Months Plan', 'Yearly Plan'].map((plan, index) => (
            <div key={index} className="bg-gray-800 p-6 rounded-lg shadow-lg hover:bg-gray-700 transition duration-300">
              <h2 className="text-2xl font-bold mb-2">{plan}</h2>
              <p className="text-xl mb-4">
                {plan === 'Weekly Plan' && 'TZS 5,000'}
                {plan === 'Monthly Plan' && 'TZS 10,000'}
                {plan === '3 Months Plan' && 'TZS 30,000'}
                {plan === '6 Months Plan' && 'TZS 60,000'}
                {plan === 'Yearly Plan' && 'TZS 120,000'}
              </p>
              <button
                className={`cursor-pointer bg-red-600 hover:bg-red-700 text-white py-2 px-4 rounded-full transition duration-300 ${loadingButtonIndex === index + 1 ? 'cursor-not-allowed' : ''}`}
                onClick={() => handleSubscribe(plan, index + 1)}
                disabled={loadingButtonIndex === index + 1}
              >
                {loadingButtonIndex === index + 1 ? 'Subscribing...' : 'Subscribe'}
              </button>
            </div>
          ))}
        </div>
        <div className="mt-8 text-6xl max-sm:text-4xl text-center font-bold text-white">
          <TextTransition springConfig={presets.wobbly}>
            {TEXTS[index % TEXTS.length]}
          </TextTransition>
        </div>
      </div>

      {/* Dialog for Voucher Validation */}
      <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-75">
          <DialogPanel className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
            <DialogTitle className="text-2xl font-bold mb-4 text-center">Enter Subscription Code</DialogTitle>
            <div className="w-full flex flex-col justify-center items-center">
              <div>
                {/* <div className="font-bold text-[18px] text-center">Plan (Number of Days)</div> */}
                <div className="font-semibold text-[18px] text-center text-green-500">
                  Tsh {parseFloat(cost || "0").toLocaleString()}
                </div>
              </div>
              <div className="max-w-[280px]">
                <div className="text-start text-[14px]">
                  To buy pre-paid code voucher send money (Tsh {parseFloat(cost || "0").toLocaleString()}) to this Mpesa Lipa Number
                  <span className="font-bold cursor-pointer hover:text-blue-500" onClick={handleCopyLipaNumber}>
                    {' '}{lipaNumber}
                  </span>
                  {' '}iTrood Distributers or call to speak to our customer care
                  <a href={`tel:${phoneNumber}`} className="font-bold hover:text-blue-500">
                    {' '}{phoneNumber}
                  </a>
                  <ToastContainer />
                </div>
              </div>
              <div className="flex flex-col w-full justify-center items-center text-center">
                {error && <div className="text-red-500">{error}</div>}
                {success && <div className="text-green-500">{success}</div>}
              </div>
              <input
                type="text"
                placeholder="Enter your voucher code"
                value={voucherCode}
                onChange={(e) => setVoucherCode(e.target.value)}
                className="border border-gray-300 rounded-md p-2 mb-4 w-full"
              />
            </div>
            <div className="flex justify-center space-x-4">
              <button
                type="button"
                className="bg-slate-600 text-white py-2 px-4 rounded-md"
                onClick={() => setIsDialogOpen(false)}
              >
                Cancel
              </button>
              <button
                type="button"
                className="bg-red-600 text-white py-2 px-4 rounded-md"
                onClick={handleVoucherValidation}
              >
                Pay
              </button>
            </div>
          </DialogPanel>
        </div>
      </Dialog>
    </div>
  );
};

const PayWall = () => {
  const { isModalVisible, closeModal } = useModal();

  return (
    <Modal show={isModalVisible} handleClose={closeModal}>
      <PayWallContent handleClose={closeModal} />
    </Modal>
  );
};

export default PayWall;