import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";
import { intro } from "../assets";

const Carousel = () => {
  return (
    <div className="h-[500px] max-sm:h-[220px] 2xl:h-[400px] w-full flex-shrink-0 bg-white">
      <Swiper
        loop={true}
        spaceBetween={0}
        navigation={true}
        modules={[Navigation, Autoplay]}
        autoplay={{
          delay: 1000,
        }}
        className="h-[90%]"
      >
        <SwiperSlide>
          <img alt="iTrood" src={"https://bgr.com/wp-content/uploads/2020/08/disney-plus.jpg?quality=82&strip=all&resize=1400,1050"} alt="iTrood" className="shrink-0 w-full object-cover" />
        </SwiperSlide>
        <SwiperSlide>
          <img alt="iTrood" src={"https://joebirch.co/wp-content/uploads/2023/02/ezgif-5-e6c624e50a.gif"} alt="iTrood" className="shrink-0 w-full object-cover" />
        </SwiperSlide>
        <SwiperSlide className="bg-black">
          <video controls muted="muted">
            <source src={intro} type="video/mp4" />
          </video>
        </SwiperSlide>
      </Swiper>
      <div className="h-[30%] bg-gradient-to-b from-stone-900" />
    </div>
  );
};

export default Carousel;