import React, { useEffect, useState, useContext } from 'react';
import { collection, getDocs, getDoc, doc, query, where, orderBy, Timestamp } from 'firebase/firestore';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { db } from '../firebase';
import { AuthContext } from '../context/AuthContext';
import { Link } from 'react-router-dom';
import { DarkModeContext } from '../context/darkModeContext';

// Register the necessary components for the Bar chart from Chart.js
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const ActivityChart = () => {
    const { darkMode } = useContext(DarkModeContext);
    const [duration, setDuration] = useState(28); // Default to 28 days
    const [chartData, setChartData] = useState(null);
    const [activeSubscribersChartData, setActiveSubscribersChartData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [usersCount, setUsersCount] = useState(0);
    const [userRegisteredCount, setUserRegisteredCount] = useState(0);
    const [activeSubscribersCount, setActiveSubscribersCount] = useState(0);
    const { currentUser } = useContext(AuthContext);

    const fetchUsersData = async () => {
        try {
            setLoading(true);

            if (!currentUser) {
                throw new Error("No current user found.");
            }

            const usersCollection = collection(db, 'users');
            let userQuery = query(usersCollection,
                where('registeredAgent', '==', currentUser.uid),
                orderBy('timeStamp', 'desc')
            );

            const userSnapshot = await getDocs(userQuery);
            const userList = userSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }));

            console.log("Fetched User List:", userList);

            const today = new Date();
            const pastDate = new Date(today.setDate(today.getDate() - duration));

            const filteredData = userList
                .filter(user => {
                    const userDate = new Date(user.timeStamp.seconds * 1000);
                    return userDate >= pastDate;
                })
                .map(user => ({
                    date: new Date(user.timeStamp.seconds * 1000).toLocaleDateString(),
                    count: 1,
                }));

            console.log("Filtered Data:", filteredData);

            const groupedData = filteredData.reduce((acc, curr) => {
                const date = curr.date;
                if (!acc[date]) {
                    acc[date] = { date, count: 0 };
                }
                acc[date].count += curr.count;
                return acc;
            }, {});

            console.log("Grouped Data:", groupedData);

            const sortedData = Object.values(groupedData).sort((a, b) => new Date(a.date) - new Date(b.date));

            const data = {
                labels: sortedData.map(item => item.date),
                datasets: [
                    {
                        label: 'User Registrations',
                        data: sortedData.map(item => item.count),
                        backgroundColor: 'rgba(75, 192, 192, 0.6)',
                        borderColor: 'rgba(75, 192, 192, 1)',
                        borderWidth: 1,
                    },
                ],
            };

            setChartData(data);
            setUsersCount(userList.length);
            setUserRegisteredCount(data.datasets[0].data.reduce((a, b) => a + b, 0));
        } catch (error) {
            console.error("Error fetching user data:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchUsersData();
        fetchActiveSubscribersData();
    }, [duration, currentUser]);

    const fetchActiveSubscribersData = async () => {
        try {
            if (!currentUser) {
                throw new Error("No current user found.");
            }

            // Get users data
            const usersCollection = collection(db, 'users');
            const userQuery = query(usersCollection, where('registeredAgent', '==', currentUser.uid), orderBy('timeStamp', 'desc'));
            const userSnapshot = await getDocs(userQuery);
            const userList = userSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

            // Fetch historical active subscribers data
            const today = new Date();
            const pastDate = new Date(today.setDate(today.getDate() - duration));

            const filteredData = userList.filter(user => {
                const userDate = new Date(user.timeStamp.seconds * 1000);
                return userDate >= pastDate;
            });

            // Fetch subscription data and check active subscriptions
            const activeSubscribersData = await Promise.all(filteredData.map(async (user) => {
                let isActive = false;
                const currentTimestamp = Timestamp.now();

                // Check premium subscription status
                const premiumSubscriptionRef = doc(db, 'premiumSubscriptions', user.id);
                const premiumDocSnapshot = await getDoc(premiumSubscriptionRef);

                if (premiumDocSnapshot.exists()) {
                    const premiumData = premiumDocSnapshot.data();
                    if (currentTimestamp <= premiumData.expireDate) {
                        isActive = true;
                    }
                }

                // Check producer subscriptions
                const subscriptionsQuery = query(collection(db, 'subscriptions'), where('userId', '==', user.id));
                const subscriptionSnapshot = await getDocs(subscriptionsQuery);

                subscriptionSnapshot.forEach(doc => {
                    const subscription = doc.data();
                    if (currentTimestamp <= subscription.expireDate) {
                        isActive = true;
                    }
                });

                return {
                    date: new Date(user.timeStamp.seconds * 1000).toLocaleDateString(),
                    count: isActive ? 1 : 0
                };
            }));

            // Aggregate and group data
            const groupedData = activeSubscribersData.reduce((acc, curr) => {
                const date = curr.date;
                if (!acc[date]) {
                    acc[date] = { date, count: 0 };
                }
                acc[date].count += curr.count;
                return acc;
            }, {});

            const sortedData = Object.values(groupedData).sort((a, b) => new Date(a.date) - new Date(b.date));

            const data = {
                labels: sortedData.map(item => item.date),
                datasets: [
                    {
                        label: 'Active Subscribers',
                        data: sortedData.map(item => item.count),
                        backgroundColor: 'rgba(153, 102, 255, 0.6)',
                        borderColor: 'rgba(153, 102, 255, 1)',
                        borderWidth: 1,
                    },
                ],
            };

            setActiveSubscribersChartData(data);
            setActiveSubscribersCount(data.datasets[0].data.reduce((a, b) => a + b, 0));
        } catch (error) {
            console.error("Error fetching active subscribers data:", error);
        }
    };

    const fetchActiveSubscribersChartData = async () => {
        try {
            if (!currentUser) {
                throw new Error("No current user found.");
            }

            const usersCollection = collection(db, 'users');
            const userQuery = query(
                usersCollection,
                where('registeredAgent', '==', currentUser.uid),
                orderBy('timeStamp', 'desc')
            );

            const userSnapshot = await getDocs(userQuery);
            const userList = userSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }));

            const today = new Date();
            const pastDate = new Date(today.setDate(today.getDate() - duration));

            const activeSubscribersData = [];

            for (const user of userList) {
                const userDate = new Date(user.timeStamp.seconds * 1000);
                if (userDate >= pastDate) {
                    let isActive = false;

                    const premiumSubscriptionRef = doc(db, 'premiumSubscriptions', user.id);
                    const premiumDocSnapshot = await getDoc(premiumSubscriptionRef);

                    if (premiumDocSnapshot.exists()) {
                        const premiumData = premiumDocSnapshot.data();
                        if (today <= premiumData.expireDate.toDate()) {
                            isActive = true;
                        }
                    }

                    if (!isActive) {
                        const subscriptionsQuery = query(collection(db, 'subscriptions'), where('userId', '==', user.id));
                        const subscriptionSnapshot = await getDocs(subscriptionsQuery);

                        subscriptionSnapshot.forEach(doc => {
                            const subscription = doc.data();
                            if (today <= subscription.expireDate.toDate()) {
                                isActive = true;
                            }
                        });
                    }

                    if (isActive) {
                        activeSubscribersData.push({
                            date: userDate.toLocaleDateString(),
                            count: 1,
                        });
                    }
                }
            }

            const groupedData = activeSubscribersData.reduce((acc, curr) => {
                const date = curr.date;
                if (!acc[date]) {
                    acc[date] = { date, count: 0 };
                }
                acc[date].count += curr.count;
                return acc;
            }, {});

            const sortedData = Object.values(groupedData).sort((a, b) => new Date(a.date) - new Date(b.date));

            const data = {
                labels: sortedData.map(item => item.date),
                datasets: [
                    {
                        label: 'Active Subscribers',
                        data: sortedData.map(item => item.count),
                        backgroundColor: 'rgba(153, 102, 255, 0.6)',
                        borderColor: 'rgba(153, 102, 255, 1)',
                        borderWidth: 1,
                    },
                ],
            };

            setActiveSubscribersChartData(data);

        } catch (error) {
            console.error("Error fetching active subscribers chart data:", error);
        }
    };

    return (
        <div className="flex flex-col w-full h-full px-1">
            <div className="text-[24px] font-bold mb-6">User Activity Dashboard</div>

            <div className='flex gap-4 w-full'>
                {/* Total Users and Word counts */}
                <div className="flex gap-4 w-[30%]">
                    <div className="w-full">
                        <Link to={`/my-users`} className="text-blue-500 cursor-pointer">Total Users</Link>
                        <div className={`h-[100px] w-full text-[#FF511B] ${!darkMode && loading ? 'bg-zinc-200 animate-pulse' : 'text-5xl'} ${darkMode && loading ? 'bg-slate-900 animate-pulse' : 'text-5xl'} font-medium rounded-[8px]`}>
                            {!loading && usersCount}
                        </div>
                    </div>
                </div>

                {/* Users Registered */}
                <div className="flex gap-4 w-[30%]">
                    <div className="w-full">
                        <Link to={`/my-users`} className="text-blue-500 cursor-pointer">Users Registered</Link>
                        <div className={`h-[100px] w-full text-[#FF511B] ${!darkMode && loading ? 'bg-zinc-200 animate-pulse' : 'text-5xl'} ${darkMode && loading ? 'bg-slate-900 animate-pulse' : 'text-5xl'} font-medium rounded-[8px]`}>
                            {!loading && userRegisteredCount}
                        </div>
                    </div>
                </div>

                {/* Active Subscribers */}
                <div className="flex gap-4 w-[30%]">
                    <div className="w-full">
                        <Link to={`/my-users`} className="text-blue-500 cursor-pointer">Active Subscribers</Link>
                        <div className={`h-[100px] w-full text-[#FF511B] ${!darkMode && loading ? 'bg-zinc-200 animate-pulse' : 'text-5xl'} ${darkMode && loading ? 'bg-slate-900 animate-pulse' : 'text-5xl'} font-medium rounded-[8px]`}>
                            {!loading && activeSubscribersCount}
                        </div>
                    </div>
                </div>
            </div>

            {/* Duration Selector */}
            <div className="mb-6 mt-4">
                <label htmlFor="duration" className="mr-2">Select Duration:</label>
                <select
                    id="duration"
                    value={duration}
                    onChange={(e) => setDuration(Number(e.target.value))}
                    className={`p-2 border rounded-md ${darkMode && 'bg-slate-900'}`}
                >
                    <option value="1">1 Day</option>
                    <option value="7">7 Days</option>
                    <option value="28">28 Days</option>
                    <option value="90">3 Months</option>
                    <option value="365">1 Year</option>
                </select>
            </div>

            {/* Chart for displaying user registration activity */}
            <div className={`w-full p-6 ${darkMode ? 'bg-slate-900' : 'bg-zinc-100'} h-fit mb-10`}>
                <h2 className="text-2xl font-bold mb-4">User Registrations Over Time</h2>

                {chartData ? (
                    <Bar
                        data={chartData}
                        options={{
                            responsive: true,
                            plugins: {
                                legend: { position: 'top' },
                                title: { display: true, text: 'User Registrations Over Time' },
                            },
                            scales: {
                                x: { title: { display: true, text: 'Date' } },
                                y: {
                                    title: { display: true, text: 'Number of Registrations' },
                                    beginAtZero: true,
                                },
                            },
                        }}
                    />
                ) : (
                    <p>Loading chart data...</p>
                )}
            </div>

            <div className={`w-full p-6 ${darkMode ? 'bg-slate-900' : 'bg-zinc-100'} h-fit mb-10`}>
                <h2 className="text-2xl font-bold mb-4">Active Subscribers Over Time</h2>

                {activeSubscribersChartData ? (
                    <Bar
                        data={activeSubscribersChartData}
                        options={{
                            responsive: true,
                            plugins: {
                                legend: { position: 'top' },
                                title: { display: true, text: 'Active Subscribers Over Time' },
                            },
                            scales: {
                                x: { title: { display: true, text: 'Date' } },
                                y: {
                                    title: { display: true, text: 'Number of Active Subscribers' },
                                    beginAtZero: true,
                                },
                            },
                        }}
                    />
                ) : (
                    <p>Loading chart data...</p>
                )}
            </div>
        </div>
    );
};

export default ActivityChart;