import { useContext, useState, useEffect } from "react";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { doc, setDoc, serverTimestamp } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { auth, db, storage } from "../firebase";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { logo2 } from "../assets";
import PropagateLoader from 'react-spinners/esm/PropagateLoader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";

const Register = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [file, setFile] = useState("");
  const [progress, setProgress] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const uploadFile = () => {
      if (!file) return;
      const name = new Date().getTime() + file.name;
      const storageRef = ref(storage, `users/${name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);
  
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setProgress(progress);
        },
        (error) => {
          console.log(error);
          toast.error("Error occurred while uploading an image. Please try again.");
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setData((prev) => ({ ...prev, img: downloadURL }));
          });
        }
      );
    };
    uploadFile();
  }, [file]);

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setData({ ...data, [id]: value });
  };

  const handleAdd = async (e) => {
    e.preventDefault();
    
    // Check if required fields are filled
    if (!data.img || !data.displayName || !data.email || !data.phone || !data.country) {
      setError("Please fill in all required fields (Image, Full Name, Email, Phone, Country).");
      return;
    }
  
    setLoading(true);
    setError(null);
  
    // Normalize email address: remove spaces and convert to lowercase
    const trimmedEmail = data.email.trim(); // Remove leading and trailing spaces
    const normalizedEmail = trimmedEmail.toLowerCase(); // Convert to lowercase
  
    try {
      const res = await createUserWithEmailAndPassword(auth, normalizedEmail, data.password);
  
      // Create the user document with the appropriate subscription value under 'users' collection
      await setDoc(doc(db, "users", res.user.uid), {
        ...data,
        email: normalizedEmail, 
        id: res.user.uid,
        verification: '',
        status: 'agent',
        earningBalance: '0',
        earningsTotalRevenue: '0',
        earningsWithdrawedAmount: '0',
        subscription: 'not-subscriber',
        timeStamp: serverTimestamp(),
        origin: 'users'
      });
      
      const prevLocation = localStorage.getItem('prevLocation');
      if (prevLocation) {
        localStorage.removeItem('prevLocation');
        // Redirect the user back to the previous location
        navigate(prevLocation);
      } else {
        // If there is no previous location, redirect the user to the home page
        navigate(-1);
      }
      setLoading(false);
      toast.success("User created successfully!");
    } catch (err) {
      console.log(err);
      setLoading(false);
      toast.error(`Error occurred while creating a user. ${err.message}`);
    }
  };

  return (
    <div className="h-screen flex flex-col items-center justify-center">
      <img src={logo2} className="w-[200px] max-md:w-[150px] shrink-0 m-10" alt="iTrood logo" />
      <form onSubmit={handleAdd} className="flex flex-col items-center">
        <div className="text-center font-bold text-slate-700 p-2">Sign Up</div>
        <img
          src={
            file
              ? URL.createObjectURL(file)
              : "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
          }
          alt="iTrood" 
          className="w-32 h-32 rounded-full object-cover mx-auto"
        />
        <input
          type="file"
          id="file"
          onChange={(e) => setFile(e.target.files[0])}
          className="m-2 w-[250px] max-md:w-[250px] h-[40px] rounded-xl scrollbar-thin border-0 resize-none shadow-md"
        />
        <div className="w-full justify-center items-center grid grid-cols-2 max-sm:flex max-sm:flex-col gap-2">
          {inputs.map((input) => (
            <div key={input.id}>
              <input
                id={input.id}
                type={input.type}
                placeholder={input.placeholder}
                required
                onChange={handleInputChange}
                className="w-[350px] max-md:w-[300px] h-10 border border-gray-300 rounded-md px-2 mt-2"
              />
            </div>
          ))}
          <div className="w-[350px] max-md:w-[300px]">
            <div className="my-2 w-full text-slate-400 bg-indigo-50 h-[40px] px-2 py-1 border-b border-slate-300 rounded scrollbar-thin border-0 resize-none shadow-sm">User Account</div>
          </div>
        </div>
        {loading ? (
          <div className="w-[350px] max-md:w-[200px] h-10 pb-4 flex bg-zinc-100 rounded-md mt-2 justify-center items-center">
            <PropagateLoader color="#3693d6" />
          </div>
        ) : (
          <button
            type="submit"
            disabled={progress !== null && progress < 100}
            className="w-[350px] max-md:w-[200px] h-10 bg-purple-500 text-white font-bold rounded-md mt-2 cursor-pointer"
          >
            Register
          </button>
        )}
        {error && <span className="text-red-500 text-sm mt-2">{error}</span>}
      </form>
      <div className="text-center text-[14px] text-zinc-400 pt-4">Already have an account?</div>
      <Link to="/login" className="text-center font-bold text-blue-500 pb-2 cursor-pointer">Sign In</Link>
    </div>
  );
};

const inputs = [
  {
    id: "displayName",
    label: "Full Name",
    type: "text",
    placeholder: "Full Name",
  },
  {
    id: "username",
    label: "Username",
    type: "text",
    placeholder: "username",
  },
  {
    id: "email",
    label: "Email",
    type: "email",
    placeholder: "Email",
  },
  {
    id: "phone",
    label: "Phone",
    type: "text",
    placeholder: "+255XXXXXXXXX",
  },
  {
    id: "password",
    label: "Password",
    type: "password",
    placeholder: "Password",
  },
  {
    id: "address",
    label: "Address",
    type: "text",
    placeholder: "Elton St. 216 Kariakoo",
  },
  {
    id: "country",
    label: "Country",
    type: "text",
    placeholder: "Tanzania",
  },
];

export default Register;