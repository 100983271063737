import Chart from "../code/Chart";
import List from "../code/Table";

const User = () => {
  return (
    <div className="flex">
      <div className="flex-1 p-4">
        <div className="p-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="border border-gray-200 p-4 relative">
              <div className="absolute top-0 right-0 p-2 text-xs text-purple-600 bg-purple-100 rounded-bl-md cursor-pointer">Edit</div>
              <h1 className="text-lg font-semibold text-gray-700">Information</h1>
              <div className="flex gap-4 mt-4">
                <div>
                  <img
                    src="https://images.pexels.com/photos/733872/pexels-photo-733872.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260"
                    alt="iTrood" 
                    className="w-24 h-24 rounded-full object-cover"
                  />
                </div>
                <div>
                  <h1 className="text-xl font-semibold text-gray-700">Jane Doe</h1>
                  <div className="mt-2">
                    <div className="flex items-center mb-1">
                      <span className="font-semibold text-gray-600 mr-2">Email:</span>
                      <span>janedoe@gmail.com</span>
                    </div>
                    <div className="flex items-center mb-1">
                      <span className="font-semibold text-gray-600 mr-2">Phone:</span>
                      <span>+1 2345 67 89</span>
                    </div>
                    <div className="flex items-center mb-1">
                      <span className="font-semibold text-gray-600 mr-2">Address:</span>
                      <span>Elton St. 234 Garden Yd. NewYork</span>
                    </div>
                    <div className="flex items-center">
                      <span className="font-semibold text-gray-600 mr-2">Country:</span>
                      <span>USA</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="border border-gray-200 p-4">
              <Chart aspect={3 / 1} title="User Spending ( Last 6 Months)" />
            </div>
          </div>
          <div className="border border-gray-200 p-4 mt-4">
            <h1 className="text-lg font-semibold text-gray-700 mb-4">Last Transactions</h1>
            <List />
          </div>
        </div>
      </div>
    </div>
  );
};

export default User;