import React, { useContext, useState } from "react";
import { Dialog, DialogPanel, DialogTitle } from '@headlessui/react';
import { doc, getDoc, updateDoc, collection, setDoc, serverTimestamp } from "firebase/firestore";
import { db, auth } from "../firebase";
import { DarkModeContext } from '../context/darkModeContext';
import { useUserData } from "../lib/userData";
import { toast } from 'react-toastify';
import { FaEye, FaEyeSlash } from "react-icons/fa";

const Withdraw = ({ balance }) => {
    const user = auth.currentUser;
    const userData = useUserData();
    const { darkMode } = useContext(DarkModeContext);
    const [isOpen, setIsOpen] = useState(false);
    const [amount, setAmount] = useState("");
    const [method, setMethod] = useState("");
    const [receiverNumber, setReceiverNumber] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [submitting, setSubmitting] = useState(false);
    const [step, setStep] = useState(1);
    const [passwordVisible, setPasswordVisible] = useState(false);

    const togglePasswordVisibility = () => {
        setPasswordVisible(prev => !prev);
    };

    const generateWithdrawID = () => {
        const randomNumber = Math.floor(Math.random() * 10000000000);
        return `W-${randomNumber.toString().padStart(10, '0')}`;
    };

    const handleContinue = (e) => {
        e.preventDefault();
        setError("");
        if (!method || !receiverNumber || !amount) {
            setError("Please fill out all fields.");
            return;
        }
        setStep(2);
    };

    const handleSubmit = async (e) => {
        setSubmitting(true);
        e.preventDefault();
        setError("");
        setSuccess("");

        const withdrawAmount = parseFloat(amount);
        if (isNaN(withdrawAmount) || withdrawAmount <= 0) {
            setError("Please enter a valid amount.");
            setSubmitting(false);
            return;
        }

        if (withdrawAmount < 1000) {
            setError("Minimum amount to withdraw is 1000.");
            setSubmitting(false);
            return;
        }

        if (!method) {
            setError("Please select a withdrawal method.");
            setSubmitting(false);
            return;
        }

        if (!receiverNumber) {
            setError("Please enter a receiver number.");
            setSubmitting(false);
            return;
        }

        if (!password) {
            setError("Please enter your password.");
            setSubmitting(false);
            return;
        }

        // Verify the user's password before proceeding
        try {
            const userDocRef = doc(db, "users", userData?.id);
            const userDoc = await getDoc(userDocRef);
            if (userDoc.exists()) {
                const userData = userDoc.data();
                const storedPassword = userData.password;

                // Compare the entered password with the stored password
                if (password !== storedPassword) {
                    setError("Password is incorrect.");
                    setSubmitting(false);
                    return;
                }
            } else {
                setError("User data not found.");
                setSubmitting(false);
                return;
            }
        } catch (error) {
            setSubmitting(false);
            setError("An error occurred while verifying the password.");
            console.error("Error verifying password: ", error);
            return;
        }

        try {
            const userDocRef = doc(db, "users", userData?.id);
            const userDoc = await getDoc(userDocRef);
            if (userDoc.exists()) {
                const userData = userDoc.data();
                const currentBalance = parseFloat(userData.earningBalance || "0");
                const earningsWithdrawedAmount = parseFloat(userData.earningsWithdrawedAmount || "0");

                if (withdrawAmount > currentBalance) {
                    setError("Insufficient balance.");
                    setSubmitting(false);
                    return;
                }

                const newBalance = currentBalance - withdrawAmount;
                const newWithdrawedAmount = earningsWithdrawedAmount + withdrawAmount;

                await updateDoc(userDocRef, {
                    earningBalance: newBalance.toString(),
                    earningsWithdrawedAmount: newWithdrawedAmount.toString(),
                });

                const withdrawID = generateWithdrawID();
                const transactionData = {
                    id: withdrawID,
                    withdrawID,
                    amount: withdrawAmount,
                    method,
                    receiverNumber,
                    timestamp: serverTimestamp(),
                    userId: userData.id
                };

                const transactionRef = doc(collection(userDocRef, "transactions"), withdrawID);
                await setDoc(transactionRef, transactionData);

                // Also save to the main 'transactions' collection
                const mainTransactionRef = doc(db, "transactions", withdrawID);
                await setDoc(mainTransactionRef, transactionData);

                setSuccess("Withdrawal successful!");
                toast.success("Withdrawal successful!");
                setSubmitting(false);
                setAmount("");
                setMethod("");
                setReceiverNumber("");
                setPassword("");
                setTimeout(() => {
                    setIsOpen(false);
                    setSuccess("");
                    setStep(1);
                }, 1000);
            } else {
                setSubmitting(false);
                setError("User data not found.");
            }
        } catch (error) {
            setSubmitting(false);
            toast.error("Error withdrawing funds, Please try again");
            console.error("Error withdrawing funds: ", error);
            setError("An error occurred while processing your request.");
        }
    };

    return (
        <div>
            <button onClick={() => setIsOpen(true)} className={`h-[40px] w-[100px] p-1 my-4 rounded-xl shadow-md ${darkMode ? "border hover:shadow-[0px_0px_4px_2px_rgba(0,_179,_255,_0.23)]" : "light-mode bg-white hover:shadow-[0px_0px_4px_2px_rgba(0,_179,_255,_0.23)]"}`}>
                Withdraw
            </button>
            <Dialog open={isOpen} onClose={() => setIsOpen(false)} className="relative z-50">
                <div className="fixed inset-0 bg-black/30 flex w-screen items-center justify-center p-4 overflow-y-auto" aria-hidden="true">
                    <DialogPanel className="flex flex-col items-center min-w-[512px] space-y-4 border bg-white/30 backdrop-blur-md p-12 rounded-2xl">
                        <DialogTitle className="font-bold text-[20px] text-center text-blue-500">Withdraw</DialogTitle>
                        <div>
                            <div className="font-bold text-[18px] text-center">BALANCE</div>
                            <div className="font-semibold text-[18px] text-center text-green-500">Tsh {parseFloat(balance || "0").toLocaleString()}</div>
                        </div>
                        <div className="flex flex-col w-full justify-center items-center text-center">
                            {error && <div className="text-red-500">{error}</div>}
                            {success && <div className="text-green-500">{success}</div>}
                        </div>
                        {step === 1 ? (
                            <form onSubmit={handleContinue} className="w-full flex flex-col items-center space-y-4">
                                <select
                                    value={method}
                                    onChange={(e) => setMethod(e.target.value)}
                                    className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
                                    disabled={submitting}
                                >
                                    <option value="">Select Withdraw Method</option>
                                    <option value="CRDB">CRDB</option>
                                    <option value="Mpesa">Mpesa</option>
                                    <option value="TigoPesa">TigoPesa</option>
                                    <option value="Airtel Money">Airtel Money</option>
                                    <option value="Halopesa">Halopesa</option>
                                    <option value="TTCL">TTCL</option>
                                    <option value="Zantel">Zantel</option>
                                </select>
                                <input
                                    type="number"
                                    value={receiverNumber}
                                    onChange={(e) => setReceiverNumber(e.target.value)}
                                    className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
                                    placeholder={method === "CRDB" ? "Enter Bank Account Number" : "Enter Phone Number"}
                                    disabled={submitting}
                                />
                                <input
                                    type="number"
                                    value={amount}
                                    onChange={(e) => setAmount(e.target.value)}
                                    className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
                                    placeholder="Amount to withdraw"
                                    disabled={submitting}
                                />
                                <div className="flex gap-4">
                                    <button type="button" onClick={() => setIsOpen(false)} className='text-center text-red-500 cursor-pointer border-spacing-4 rounded-xl border-red-500 hover:bg-red-500 hover:text-white hover:font-bold border-2 p-2 w-fit' disabled={submitting}>Cancel</button>
                                    <button type="submit" className='text-center text-blue-500 cursor-pointer border-spacing-4 rounded-xl border-blue-500 hover:bg-blue-500 hover:text-white hover:font-bold border-2 p-2 w-fit' disabled={submitting}>
                                        Continue
                                    </button>
                                </div>
                            </form>
                        ) : (
                            <form onSubmit={handleSubmit} className="w-full flex flex-col items-center space-y-4">
                                <div className="w-full p-2 border rounded-md">{`Method: ${method}`}</div>
                                <div className="w-full p-2 border rounded-md">{`Receiver Number: ${receiverNumber}`}</div>
                                <div className="w-full p-2 border rounded-md">{`Amount: ${amount}`}</div>
                                <div className="relative">
                                    <input
                                        type={passwordVisible ? "text" : "password"}
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400 pr-10" // Adjust padding-right
                                        placeholder="Enter your password"
                                        disabled={submitting}
                                    />
                                    <div
                                        onClick={togglePasswordVisibility}
                                        className="absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer text-gray-500"
                                    >
                                        {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                                    </div>
                                </div>
                                <div className="flex gap-4">
                                    {submitting ? (
                                        <div className='text-center text-slate-300 border-spacing-4 rounded-xl bg-zinc-500 font-bold border-2 p-2 w-fit'>Submitting...</div>
                                    ) : (
                                        <>
                                            <button type="button" onClick={() => setStep(1)} className='text-center text-yellow-500 cursor-pointer border-spacing-4 rounded-xl border-yellow-500 hover:bg-yellow-500 hover:text-white hover:font-bold border-2 p-2 w-fit' disabled={submitting}>Back</button>
                                            <button type="submit" className='text-center text-blue-500 cursor-pointer border-spacing-4 rounded-xl border-blue-500 hover:bg-blue-500 hover:text-white hover:font-bold border-2 p-2 w-fit' disabled={submitting}>
                                                Submit
                                            </button>
                                        </>
                                    )}
                                </div>
                            </form>
                        )}
                    </DialogPanel>
                </div>
            </Dialog>
        </div>
    );
};

export default Withdraw;