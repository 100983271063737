import React from 'react'
import { facebook, instagram, twitter, youtube } from '../assets'

const Footer = () => {
  return (
    <div className='bottom-0 w- flex justify-between items-center px-5'>
      <div className='text-sm'>Copyright © 2024 All rights reserved <strong><a a href='https://itrood.com/' target='_blank'>iTrood, Inc</a></strong></div>
      <div className='flex gap-0'>
        <a href='https://facebook.com/itr00d' target='_blank' className='bg-zinc-100 justify-center items-center flex-shrink-0 rounded-full cursor-pointer' title='Facebook'>
          <img alt="iTrood" src={facebook} className='shrink-0 rounded-3xl' />
        </a>
        <a href='https://instagram.com/itr00d' target='_blank' className='bg-zinc-100 justify-center items-center flex-shrink-0 rounded-full cursor-pointer' title='Instagram'>
          <img alt="iTrood" src={instagram} className='shrink-0 rounded-3xl' />
        </a>
        <a href='https://twitter.com/itr00d' target='_blank' className='bg-zinc-100 justify-center items-center flex-shrink-0 rounded-full cursor-pointer' title='X | Twitter'>
          <img alt="iTrood" src={twitter} className='shrink-0 rounded-3xl' />
        </a>
        <a href='https://youtube.com/itr00d' target='_blank' className='bg-zinc-100 justify-center items-center flex-shrink-0 rounded-full cursor-pointer' title='YouTube'>
          <img alt="iTrood" src={youtube} className='shrink-0 rounded-3xl' />
        </a>
      </div>
    </div>
  )
}

export default Footer
