import { createContext, useEffect, useReducer } from "react";
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword } from "firebase/auth";
import AuthReducer from "./AuthReducer";
import { auth } from "../firebase";

const INITIAL_STATE = {
  currentUser: JSON.parse(localStorage.getItem("user")) || null,
};

export const AuthContext = createContext(INITIAL_STATE);

export const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AuthReducer, INITIAL_STATE);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        // User is signed in
        dispatch({ type: "LOGIN", payload: user });
      } else {
        // No user is signed in
        const localUser = JSON.parse(localStorage.getItem("user"));
        if (localUser) {
          // Attempt to sign in using locally stored user data
          try {
            const { email, password } = localUser;
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            dispatch({ type: "LOGIN", payload: userCredential.user });
          } catch (error) {
            console.error("Error signing in with local user data:", error);
            dispatch({ type: "LOGOUT" });
          }
        } else {
          // No user data stored locally, clear currentUser
          dispatch({ type: "LOGOUT" });
        }
      }
    });

    return () => unsubscribe();
  }, []);

  // Update local storage when currentUser changes
  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(state.currentUser));
  }, [state.currentUser]);

  return (
    <AuthContext.Provider value={{ currentUser: state.currentUser, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};
