import React, { useContext, useEffect, useState } from 'react';
import { collection, query, where, getDocs, doc, getDoc, orderBy, Timestamp, getFirestore } from 'firebase/firestore';
import { db } from '../firebase';
import { AuthContext } from '../context/AuthContext';
import PayWall from './PayWall';
import { useModal } from '../context/ModalContext';
import Subscribe from './Subscribe';

const PAGE_SIZE = 10;

const MyUsers = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedUser, setSelectedUser] = useState(null);
  const { currentUser } = useContext(AuthContext);
  const [selectedPlan, setSelectedPlan] = useState('');
  const [selectedProducer, setSelectedProducer] = useState(null);
  const [producers, setProducers] = useState([]);
  const [producerNames, setProducerNames] = useState({});
  const [isSubscriber, setIsSubscriber] = useState({});

  const fetchUsers = async () => {
    try {
      if (!currentUser) {
        setError("No current user found.");
        setLoading(false);
        return;
      }

      const usersCollection = collection(db, 'users');
      const userQuery = query(
        usersCollection,
        where('registeredAgent', '==', currentUser.uid),
        orderBy('timeStamp', 'desc')
      );

      const userSnapshot = await getDocs(userQuery);

      const userList = userSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));

      setUsers(userList);

      const totalUsers = userList.length;
      setTotalPages(Math.ceil(totalUsers / PAGE_SIZE));

      // Check subscription status for all users
      userList.forEach(user => checkSubscriptionStatus(user.id));

    } catch (error) {
      console.error("Error fetching users:", error);
      setError("Failed to fetch users.");
    } finally {
      setLoading(false);
    }
  };

  const checkSubscriptionStatus = async (userId) => {
    try {
      let currentTimestamp;

      // Fetch server time
      const serverTimeDoc = await getDoc(doc(getFirestore(), 'serverTime', 'serverTimeDoc'));
      currentTimestamp = serverTimeDoc.exists() ? serverTimeDoc.data().timestamp : Timestamp.now();

      const status = {};

      // Check if user has a premium subscription
      const premiumSubscriptionRef = doc(db, 'premiumSubscriptions', userId);
      const premiumDocSnapshot = await getDoc(premiumSubscriptionRef);

      if (premiumDocSnapshot.exists()) {
        const premiumData = premiumDocSnapshot.data();
        if (currentTimestamp <= premiumData.expireDate) {
          status.premium = 'Active Subscriber';
        } else {
          status.premium = 'Expired Subscription';
        }
      } else {
        status.premium = 'Not Subscribed';
      }

      // Check if user is subscribed to producers
      const subscriptionsQuery = query(collection(db, 'subscriptions'), where('userId', '==', userId));
      const subscriptionSnapshot = await getDocs(subscriptionsQuery);

      let activeProducers = [];
      let producerStatus = 'Not Subscribed';

      subscriptionSnapshot.forEach(doc => {
        const subscription = doc.data();
        if (currentTimestamp <= subscription.expireDate) {
          activeProducers.push(subscription.producerId);
          producerStatus = 'Actively Subscribed to Multiple Producers';
        }
      });

      // If user is subscribed to multiple active producers
      if (activeProducers.length > 1) {
        producerStatus = 'Actively Subscribed to Multiple Producers';
      } else if (activeProducers.length === 1) {
        producerStatus = 'Actively Subscribed to Producer';
      } else {
        producerStatus = 'Not Subscribed';
      }

      setIsSubscriber(prevState => ({
        ...prevState,
        [userId]: {
          premium: status.premium,
          producer: producerStatus,
          activeProducers
        }
      }));

    } catch (error) {
      console.error('Error checking subscription status:', error);
    }
  };

  const getProducerNameById = async (producerId) => {
    try {
      const producerRef = doc(db, 'users', producerId);
      const producerDoc = await getDoc(producerRef);

      if (producerDoc.exists()) {
        return producerDoc.data().displayName;
      } else {
        console.error('No such producer found!');
        return 'Unknown Producer';
      }
    } catch (error) {
      console.error('Error fetching producer name:', error);
      return 'Error Fetching Producer';
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const { openModal } = useModal();

  const fetchProducers = async () => {
    try {
      const usersRef = collection(db, 'users');
      const q = query(usersRef, where('status', '==', 'producer'));
      const querySnapshot = await getDocs(q);

      const fetchedProducers = [];
      const names = {}; // Temporary object to store producer names

      querySnapshot.forEach((doc) => {
        const producer = doc.data();
        const producerId = doc.id;

        fetchedProducers.push({
          id: producerId,
          displayName: producer.displayName,
          img: producer.img,
          verification: producer.verification,
          country: producer.country,
          followers: producer.followers || [],
        });

        // Fetch the name asynchronously
        getProducerNameById(producerId).then(name => {
          names[producerId] = name;
          setProducerNames(prevNames => ({ ...prevNames, ...names }));
        });
      });

      setProducers(fetchedProducers);
    } catch (error) {
      console.error('Error fetching producers: ', error);
    }
  };

  useEffect(() => {
    fetchProducers();
  }, []);

  const handleSelectPlan = (plan) => {
    setSelectedPlan(plan);
    if (plan === 'Producer') {
      fetchProducers();
    }
  };

  const openSubscribeModal = (user) => {
    setSelectedUser(user);
    setSelectedPlan('');

    // Filter out sensitive fields
    const { address, country, displayName, email, id, img, origin, phone, registeredAgent, status, subscription, username } = user;

    // Create a filtered user object
    const filteredUser = {
      address,
      country,
      displayName,
      email,
      id,
      img,
      origin,
      phone,
      registeredAgent,
      status,
      subscription,
      username
    };

    // Save the filtered user object to local storage
    localStorage.setItem('selectedUser', JSON.stringify(filteredUser));
  };

  const closeSubscribeModal = () => {
    setSelectedUser(null);
    setSelectedPlan('');
    setSelectedProducer(null);
    // Clear the selected user from local storage
    localStorage.removeItem('selectedUser');
  };

  const handleProducerSelection = (producerId) => {
    setSelectedProducer(producerId);
  };

  const handleSubscribe = () => {
    if (selectedPlan === 'Premium') {
      console.log(`Subscribed ${selectedUser.displayName} to Premium.`);
    } else if (selectedPlan === 'Producer' && selectedProducer) {
      console.log(`Subscribed ${selectedUser.displayName} to producer ${selectedProducer}.`);
    } else {
      alert('Please select a valid option.');
      return;
    }
    closeSubscribeModal();
  };

  const producerSubscriptionCost = '3000';

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="flex flex-col w-full p-4">
      <h2 className='text-xl font-bold mb-4'>Users</h2>
      <div className='grid grid-cols-1 gap-4'>
        {users.length === 0 ? (
          <div>No users found.</div>
        ) : (
          users.map(user => (
            <div key={user.id} className="flex justify-between items-center p-4 border-b border-gray-200">
              <div className='flex'>
                <img
                  src={user?.img || 'https://via.placeholder.com/50'}
                  alt={user?.displayName || 'User Image'}
                  className="w-12 h-12 rounded-full object-cover mr-4"
                />
                <div>
                  <div className="font-semibold text-lg">{user?.displayName || 'No Name'}</div>
                  <div className="text-gray-600">{user?.email || 'No Email'}</div>
                </div>
              </div>
              <div className='flex flex-col items-center'>
                {isSubscriber[user.id]?.premium === 'Active Subscriber' ? (
                  <div className='text-green-500 text-xs font-bold'>Active Subscriber</div>
                ) : isSubscriber[user.id]?.premium === 'Expired Subscription' ? (
                  <div
                    className='cursor-pointer rounded-lg bg-red-500 hover:bg-blue-500 text-white font-bold text-center justify-center items-center flex p-2 h-[40px] w-[100px]'
                    onClick={() => openSubscribeModal(user)}
                  >
                    Subscribe
                  </div>
                ) : (
                  <div
                    className='cursor-pointer rounded-lg bg-red-500 hover:bg-blue-500 text-white font-bold text-center justify-center items-center flex p-2 h-[40px] w-[100px]'
                    onClick={() => openSubscribeModal(user)}
                  >
                    Subscribe
                  </div>
                )}

                {isSubscriber[user.id]?.producer === 'Actively Subscribed to Multiple Producers' && (
                  <div>
                    <div className='text-green-500 text-xs font-bold'>Producers</div>
                    <div>
                      {isSubscriber[user.id]?.activeProducers.map(producerId => {
                        const producerName = producerNames[producerId] || 'Loading...';
                        return (
                          <div key={producerId} className='text-green-500 text-xs'>{producerName}</div>
                        );
                      })}
                    </div>
                  </div>
                )}

                {isSubscriber[user.id]?.producer === 'Actively Subscribed to Producer' && (
                  <div className='text-green-500 text-xs font-bold'>Actively Subscribed to Producer</div>
                )}

                {isSubscriber[user.id]?.producer === 'Expired Producer Subscription' && (
                  <div
                    className='cursor-pointer rounded-lg bg-red-500 hover:bg-blue-500 text-white font-bold text-center justify-center items-center flex p-2 h-[40px] w-[100px]'
                    onClick={() => openSubscribeModal(user)}
                  >
                    Subscribe
                  </div>
                )}
              </div>
            </div>
          ))
        )}
      </div>

      {/* Pagination */}
      < div className='flex justify-center mt-4' >
        <button
          className='bg-gray-300 p-2 rounded-md mx-1'
          disabled={currentPage === 1}
          onClick={() => setCurrentPage(currentPage - 1)}
        >
          Previous
        </button>
        <span className='px-3 py-2'>
          Page {currentPage} of {totalPages}
        </span>
        <button
          className='bg-gray-300 p-2 rounded-md mx-1'
          disabled={currentPage === totalPages}
          onClick={() => setCurrentPage(currentPage + 1)}
        >
          Next
        </button>
      </div >

      {/* Subscribe Modal */}
      {
        selectedUser && (
          <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-0">
            <div className="bg-white p-6 rounded-lg shadow-lg w-[300px]">
              <h3 className="text-xl font-bold mb-4">Subscribe {selectedUser.displayName} to</h3>
              <div
                className={`p-1 flex rounded border cursor-pointer hover:border-blue-400 text-center m-1 ${selectedPlan === 'Premium' && 'bg-blue-500 text-white'}`}
                onClick={() => {
                  handleSelectPlan('Premium');
                  openModal();
                }}
              >
                Premium
              </div>

              <div
                className={`p-1 flex rounded border cursor-pointer hover:border-blue-400 text-center m-1 ${selectedPlan === 'Producer' && 'bg-blue-500 text-white'}`}
                onClick={() => handleSelectPlan('Producer')}
              >
                Producer
              </div>
              {selectedPlan === 'Premium' && (
                <div className='mt-2'>
                  <div className='text-center'>Unlimited access to all films and movies</div>
                </div>
              )}
              {selectedPlan === 'Producer' && (
                <div className='flex flex-col mt-2 w-full items-center'>
                  <label className="block text-gray-700">Select Producer:</label>
                  <select
                    className="w-full border rounded p-2 mt-1"
                    value={selectedProducer}
                    onChange={(e) => handleProducerSelection(e.target.value)}
                  >
                    <option value="">Choose a producer</option>
                    {producers.map((producer) => (
                      <option key={producer.id} value={producer.id}>
                        {producer.displayName} - {producer.country}
                      </option>
                    ))}
                  </select>
                  {selectedProducer && (
                    <div className="my-2 flex items-center">
                      <img
                        src={producers.find(p => p.id === selectedProducer)?.img || 'https://via.placeholder.com/50'}
                        alt="Producer"
                        className="w-12 h-12 rounded-full mr-4"
                      />
                      <div>
                        <div className="font-semibold">{producers.find(p => p.id === selectedProducer)?.displayName}</div>
                        <div className="text-gray-600">{producers.find(p => p.id === selectedProducer)?.country}</div>
                      </div>
                    </div>
                  )}
                  {selectedProducer && (
                    <Subscribe cost={producerSubscriptionCost} producerId={selectedProducer} />
                  )}
                </div>
              )}
              <div className="flex justify-between mt-4">
                <button
                  className="bg-gray-500 text-white px-4 py-2 rounded mr-2"
                  onClick={closeSubscribeModal}
                >
                  Cancel
                </button>
                <button
                  className="bg-blue-500 text-white px-4 py-2 rounded"
                  onClick={handleSubscribe}
                >
                  Done
                </button>
              </div>
            </div>
          </div>
        )
      }

      <PayWall
        user={selectedUser}
        isOpen={!!selectedUser && !selectedPlan}
        onClose={closeSubscribeModal}
      />
    </div >
  );
};

export default MyUsers;