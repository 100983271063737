import { useState, useEffect } from 'react';
import { getCurrentUserData } from '../auth';

export const useUserData = () => {
  const [userData, setUserData] = useState(null);
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    // Check for online/offline status
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  useEffect(() => {
    const fetchDataFromDatabase = () => {
      // Fetch user data from the database
      getCurrentUserData((data) => {
        // Update user data in local storage
        localStorage.setItem('userData', JSON.stringify(data));
        // Update user data in session storage
        sessionStorage.setItem('userData', JSON.stringify(data));
        // Update user data in state
        setUserData(data);
      });
    };

    // Fetch user data from local storage by default
    const userDataFromLocalStorage = localStorage.getItem('userData');
    if (userDataFromLocalStorage) {
      setUserData(JSON.parse(userDataFromLocalStorage));
    }

    // If online, fetch data from the database immediately
    if (isOnline) {
      fetchDataFromDatabase();
    }

    // Set up interval to check for updates every 0.1 second
    const interval = setInterval(() => {
      if (isOnline) {
        fetchDataFromDatabase();
      }
    }, 100);

    // Clean up interval when component unmounts
    return () => clearInterval(interval);
  }, [isOnline]);

  return userData;
};