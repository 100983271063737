import React, { useState, useEffect } from "react";
// import { getToken } from "firebase/messaging";
// import { messaging } from './firebase';
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Outlet,
  Navigate,
} from "react-router-dom";
import { useContext } from "react";
import { ToastContainer } from 'react-toastify';
import { productInputs, userInputs } from "./formSource";
import { auth, db } from './firebase';
import { collection, doc, getDoc, updateDoc, onSnapshot, serverTimestamp, arrayUnion, increment, setDoc } from 'firebase/firestore';
import Intro from "./Intro";
import Register from "./screens/Register";
import RegisterProfile from "./screens/RegisterProfile";
import Login from "./screens/Login";
import AgentDashboard from "./screens/AgentDashboard";

import Navbar from "./components/Navbar";
import SideBar from "./components/SideBar";
import Footer from "./components/Footer";

import Users from "./screens/Users";
import Admin from "./pages/Admin";
import Support from "./pages/Support";
import Producer from "./pages/Producer";
import Distributor from './pages/Distributor';
import User from "./pages/User";
import Distributors from "./pages/Distributors";
import Producers from "./pages/Producers";
import Trendings from "./pages/Trendings";

import Profile from "./pages/Profile";
import RecycleBin from "./pages/RecycleBin";
import ComingSoon from "./pages/ComingSoon";

import Library from "./screens/Library";
import Movies from "./screens/Movies";
import Film from "./pages/Film";
import Movie from "./pages/Movie";
import EditFilm from "./pages/EditFilm";
import EditMovie from "./pages/EditMovie";

import Posts from "./screens/Posts";
import New from "./screens/New";

import Create from "./screens/Create";
import CreatePost from "./pages/CreatePost";
import CreateRelease from "./pages/CreateRelease";
import CreateUser from "./pages/CreateUser";
import Upload from "./pages/Upload";

import ErrorBoundary from "./ErrorBoundary";
import AuthLayout from "./AuthLayout";

import { DarkModeContext } from './context/darkModeContext';
import { AuthContext } from './context/AuthContext';
import { updateCurrentUser } from "firebase/auth";

import Messages from "./screens/Messages";
import Feedbacks from "./pages/Feedbacks";
import Notifications from "./pages/Notifications";
import MobileBottomNavigationBar from "./components/MobileBottomNavigationBar";
import EditProfile from "./pages/EditProfile";
// import SearchResult from "./pages/SearchResult";
import { signInWithEmailAndPassword } from "firebase/auth";
import CryptoJS from "crypto-js";
import PayWall from "./pages/PayWall";
import { useModal } from "./context/ModalContext";
import axios from "axios";
import MyUsers from "./pages/MyUsers";
import Sales from "./pages/Sales";
import Revenue from "./pages/Revenue";

const secretKey = "8b364ea665d053603748ccdf2c181573adcac5c3f9a0f34860ac23a7f15f7c06";

const PrivateRoute = ({ element, ...props }) => {
  const { currentUser } = useContext(AuthContext);
  return currentUser ? element : <Navigate to="/login" />;
};

const PublicRoute = ({ element, ...props }) => {
  const { currentUser } = 'null';
  return currentUser ? <Navigate to="/" /> : element;
};

const Layout = () => {
  const { darkMode } = useContext(DarkModeContext);

  return (
    <>
      <div className={`h-screen w-screen bg-[#F9FAFB] ${darkMode ? "bg-[#1b082b] text-white" : "light-mode"}`}>
        <div className={`flex w-full h-full justify-center fixed ${darkMode ? "bg-[#1b082b] text-white" : "light-mode"}`}>
          <SideBar />
          <div className={`flex-1 mx-5 max-md:mx-auto mr-12 h-full md:min-w-[1024px] max-sm:w-full max-sm:mx-1 ${darkMode ? "bg-[#1b082b] text-white" : "light-mode"}`}>
            <Navbar />
            {/* Main */}
            <div className={`flex flex-col h-full justify-between flex-1 overflow-y-auto ${darkMode ? "bg-[#1b082b] text-white" : "light-mode"}`}>
              <div className={`mx-5 mr-5 max-sm:mx-1 h-full overflow-y-auto scrollbar-hide ${darkMode ? "bg-[#1b082b] text-white" : "light-mode"}`}>
                <Outlet />
                {/* <SearchResult /> */}
              </div>
              <div className={`w-full mt-auto mb-[90px] md:hidden ${darkMode ? "bg-[#40394d5d] text-white" : "light-mode bg-gray-100"}`}>
                <MobileBottomNavigationBar />
              </div>
              <div className={`w-full mt-auto mb-[90px] rounded-xl max-md:hidden ${darkMode ? "bg-[#40394d5d] text-white" : "light-mode bg-gray-100"}`}>
                <Footer />
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
      </div>
    </>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: "/",
        element: <PrivateRoute element={<AgentDashboard />} />,
      },
      {
        path: "/sales",
        element: <PrivateRoute element={<Sales />} />,
      },
      {
        path: "/revenue",
        element: <PrivateRoute element={<Revenue />} />,
      },
      {
        path: "/producers",
        element: <PrivateRoute element={<Producers />} />,
      },
      {
        path: "/edit-profile",
        element: <PrivateRoute element={<EditProfile />} />,
      },
      {
        path: "/profile",
        element: <PrivateRoute element={<Profile />} />,
      },
      {
        path: "/users/user/:id",
        element: <PrivateRoute element={<User />} />,
      },
      {
        path: "/create",
        element: <PrivateRoute element={<Create />} />,
      },
      {
        path: "/create/user",
        element: <PrivateRoute element={<CreateUser inputs={userInputs} title="Registration" />} />,
      },
      {
        path: "/my-users",
        element: <PrivateRoute element={<MyUsers />} />,
      },
    ],
  },
  {
    element: <AuthLayout />,
    children: [
      {
        path: "/login",
        element: <PublicRoute element={<Login />} />,
        // loader: redirectIfUser,
      },
      {
        path: "/register",
        element: <PublicRoute element={<Register inputs={userInputs} title="Register Profile" />} />,
      },
      {
        path: "logout",
        // action: logoutUser,
      },
    ]
  }
]);

const App = () => {
  const [showIntro, setShowIntro] = useState(true);
  const { currentUser, dispatch } = useContext(AuthContext);
  const { openModal, closeModal } = useModal();

  const getIpAddress = async () => {
    try {
      const response = await axios.get("https://api.ipify.org?format=json");
      return response.data.ip;
    } catch (error) {
      console.error("Error fetching IP address:", error);
      return null;
    }
  };

  const storeVisitor = async () => {
    try {
      const ipAddress = await getIpAddress();

      if (ipAddress) {
        const today = new Date().toISOString().split('T')[0];

        const visitorRef = doc(db, "dailyAgentVisitors", today);
        const visitorDoc = await getDoc(visitorRef);

        if (!visitorDoc.exists()) {
          await setDoc(visitorRef, {
            visitors: [ipAddress],
            count: 1,
            date: today,
            timestamp: serverTimestamp(),
          });
        } else {
          const data = visitorDoc.data();
          if (!data.visitors.includes(ipAddress)) {
            await updateDoc(visitorRef, {
              visitors: arrayUnion(ipAddress),
              count: increment(1),
            });
          }
        }
      }
    } catch (error) {
      console.error("Error storing visitor:", error);
    }
  };

  storeVisitor();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const email = params.get('email');
    if (email) {
      localStorage.setItem('email', email);
    }

    const timer = setTimeout(() => {
      setShowIntro(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const storedEmail = localStorage.getItem("email");
    const storedEncryptedPassword = localStorage.getItem("password");

    if (storedEmail && storedEncryptedPassword) {
      const bytes = CryptoJS.AES.decrypt(storedEncryptedPassword, secretKey);
      const decryptedPassword = bytes.toString(CryptoJS.enc.Utf8);

      signInWithEmailAndPassword(auth, storedEmail, decryptedPassword)
        .then((userCredential) => {
          const user = userCredential.user;
          // console.log(user);
          localStorage.setItem("user", JSON.stringify(user));
          dispatch({ type: "LOGIN", payload: user });
        })
        .catch((error) => {
          console.log("Auto sign-in failed. Redirecting to login page.");
        });
    } else {
      console.log("No stored credentials found. Redirecting to login page.");
    }
  }, [dispatch]);

  return (
    <div className="">
      {showIntro ? (
        <Intro />
      ) : (
        <RouterProvider router={router} />
      )}
    </div>
  );
};

export default App;