import React, { useContext, useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../firebase";
import { DarkModeContext } from '../context/darkModeContext';
import { MdVerified, MdOutlineVerified } from "react-icons/md";

const Producers = () => {
  const { dispatch, darkMode } = useContext(DarkModeContext);
  const [producers, setProducers] = useState([]);
  const [topProducers, setTopProducers] = useState([]);

  useEffect(() => {
    const fetchProducers = async () => {
      try {
        // Fetch all producers
        const usersRef = collection(db, 'users');
        const q = query(usersRef, where('status', '==', 'producer'));
        const querySnapshot = await getDocs(q);

        const fetchedProducers = [];
        querySnapshot.forEach((doc) => {
          const producer = doc.data();
          fetchedProducers.push({
            id: doc.id,
            displayName: producer.displayName,
            img: producer.img,
            verification: producer.verification,
            country: producer.country,
            status: producer.status,
            followers: producer.followers || [],
            moviesCount: 0, // Initialize moviesCount
          });
        });

        // Fetch all movies to check the producers
        const moviesRef = collection(db, 'movies');
        const moviesSnapshot = await getDocs(moviesRef);

        const moviesByProducer = {};
        moviesSnapshot.forEach((doc) => {
          const movie = doc.data();
          const producerId = movie.ProducerID;

          if (!moviesByProducer[producerId]) {
            moviesByProducer[producerId] = 0;
          }
          moviesByProducer[producerId] += 1;
        });

        // Filter producers who have at least one movie released
        const producersWithMovies = fetchedProducers.filter((producer) => {
          const moviesCount = moviesByProducer[producer.id] || 0;
          if (moviesCount > 0) {
            producer.moviesCount = moviesCount;
            return true;
          }
          return false;
        });

        setProducers(producersWithMovies);

        // Fetch top ranking producers based on some criteria (e.g., total sales)
        const earningsRef = collection(db, 'earnings');
        const earningsSnapshot = await getDocs(earningsRef);

        // Calculate total earnings for each producer
        const producerSales = {};
        earningsSnapshot.forEach((doc) => {
          const earning = doc.data();
          const producerId = earning.seller;
          const salesValue = parseFloat(earning.value); // Assuming value is stored as string

          if (!producerSales[producerId]) {
            producerSales[producerId] = 0;
          }
          producerSales[producerId] += salesValue;
        });

        // Convert producerSales object to array for sorting
        const sortedProducers = Object.keys(producerSales).map((producerId) => ({
          id: producerId,
          totalSales: producerSales[producerId],
        })).sort((a, b) => b.totalSales - a.totalSales); // Sort by totalSales descending

        // Limit to top producers (e.g., top 5)
        const topProducersData = sortedProducers.slice(0, 5);

        // Fetch details of top producers from 'users' collection
        const topProducersDetails = [];
        for (const producer of topProducersData) {
          const producerDoc = await getDocs(query(usersRef, where('id', '==', producer.id)));
          producerDoc.forEach((doc) => {
            const producerData = doc.data();
            topProducersDetails.push({
              id: doc.id,
              displayName: producerData.displayName,
              img: producerData.img,
              verification: producerData.verification,
              country: producerData.country,
              status: producerData.status,
              followers: producerData.followers,
              totalSales: producer.totalSales,
            });
          });
        }

        setTopProducers(topProducersDetails);
      } catch (error) {
        console.error('Error fetching producers: ', error);
      }
    };

    fetchProducers();
  }, []);

  return (
    <div className='mx-auto px-4 py-8'>
      <h2 className="text-3xl font-semibold mb-4">Top Ranking DJs</h2>
      <div className='flex w-full flex-wrap gap-4 my-2'>
        {topProducers.slice(0, 3).map((producer, index) => (
          <div to={`/producer/${producer.id}`} key={index} className="flex flex-col rounded-2xl shadow-xl text-white bg-black h-[150px] w-[280px] max-sm:w-full p-4">
            <div className="flex w-full flex-wrap gap-4 items-center">
              <img
                src={producer.img}
                className="rounded-full object-cover h-[50px] w-[50px] bg-zinc-100 hover:bg-zinc-200"
                alt="Producer"
              />
              <div className="flex flex-col">
                <div className='flex justify-center items-center'>
                  <div className='font-semibold text-center line-clamp-1'>{producer.displayName}</div>
                  {producer?.verification === "verified" && (
                    <div className='text-[13px] font-semibold text-center'>{darkMode ? <MdOutlineVerified /> : <MdVerified color='blue' />}</div>
                  )}
                </div>
                <div className="text-sm">{producer.country}</div>
                <div className="text-xs text-gray-400">{producer.status}</div>
                <div className="text-xs text-gray-400">Followers {producer.followers?.length.toLocaleString() || '0'}</div>
                {/* <div className="text-xs text-gray-400">Total Sales: TZS {producer.totalSales.toLocaleString()}</div> */}
                <div className="text-xs text-yellow-400">Rank No: {index + 1}</div> {/* Display rank */}
              </div>
            </div>
          </div>
        ))}
      </div>

      <h2 className="text-3xl font-semibold mb-4">Movie Producers</h2>
      <div className='w-full flex flex-wrap gap-4 my-2 overflow-y-auto scrollbar-hide'>
        {producers.map((producer) => (
          <div to={`/producer/${producer.id}`} key={producer.id} title={producer.displayName} className='cursor-pointer h-[150px] w-[100px] justify-center'>
            <img alt={producer.displayName} src={producer.img} className='h-[100px] w-[100px] rounded-full flex-shrink-0 border shadow-lg object-cover' title={producer.displayName} />
            <div className='gap-1 flex justify-center items-center'>
              <div className='text-[14px] font-semibold text-center line-clamp-1'>{producer.displayName}</div>
              {producer?.verification === "verified" && (
                <div className='text-[13px] font-semibold text-center'>{darkMode ? <MdOutlineVerified /> : <MdVerified color='blue' />}</div>
              )}
            </div>
            <div className='text-[12px] text-center text-gray-400'>{producer.moviesCount} Movies</div>
            <div className='text-[12px] text-center text-gray-400'>{producer.followers.length} Followers</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Producers;