import React, { useContext, useEffect, useState } from "react";
import { collection, query, where, getDocs, Timestamp } from "firebase/firestore";
import { db } from "../firebase";
import { DarkModeContext } from '../context/darkModeContext';
import { Link } from 'react-router-dom';
import CountUp from 'react-countup';
import { useUserData } from "../lib/userData";
import Withdraw from "../pages/Withdraw";
import ActivityChart from "../components/ActivityChart";

const AgentDashboard = () => {
  const userData = useUserData();
  const { darkMode } = useContext(DarkModeContext);
  const [totalSeasons, setTotalSeasons] = useState(0);
  const [totalSingleFilms, setTotalSingleFilms] = useState(0);

  return (
    <div className="flex flex-col gap-4 w-full">
      <div className='w-full'>
        <div className='font-bold text-[18px]'>Overview</div>
      </div>
      <div className='flex flex-row max-sm:flex-col flex-nowrap w-full md:pr-[80px] justify-start max-w-full min-w-0 gap-4 px-[6px]'>
        <div className={`h-[140px] w-[35%] max-sm:min-w-full md:min-w-[35%] p-4 my-2 rounded-2xl ${darkMode ? "hover:shadow-[0px_0px_4px_2px_rgba(0,_179,_255,_0.23)]" : "light-mode bg-white hover:shadow-[0px_0px_4px_2px_rgba(0,_179,_255,_0.23)]"}`}>
          <Link to={`/sales`} className='font-semibold cursor-pointer hover:text-blue-500 hover:underline'>Sale's Earnings</Link>
          <div className='text-[30px] font-bold text-slate-400'>Today</div>
          <div className='flex w-[50%] gap-2 justify-between'>
            <div>
              <div className='text-[14px] text-slate-600 font-semibold'><CountUp start={0} end={totalSeasons?.toLocaleString()} duration={2} /></div>
              <div className='text-[10px] text-slate-400 font-bold'>Users</div>
            </div>
            <div>
              <div className='text-[14px] text-slate-600 font-semibold'><CountUp start={0} end={totalSeasons?.toLocaleString()} duration={2} /></div>
              <div className='text-[10px] text-slate-400 font-bold'>Subscriptions</div>
            </div>
          </div>
        </div>
        <div className={`flex flex-col items-center h-[140px] w-[30%] max-sm:min-w-full md:min-w-[35%] p-4 my-2 rounded-2xl ${darkMode ? "hover:shadow-[0px_0px_4px_2px_rgba(0,_179,_255,_0.23)]" : "light-mode bg-white hover:shadow-[0px_0px_4px_2px_rgba(0,_179,_255,_0.23)]"}`}>
          <div className='font-semibold cursor-pointer hover:text-blue-500'>Balance</div>
          <div className='text-[40px] font-bold text-[#15fac3]'><strong className="text-[12px]">Tzs</strong> <CountUp start={0} end={userData?.earningBalance?.toLocaleString()} duration={2} /></div>
          <Withdraw balance={userData?.earningBalance?.toLocaleString()} />
        </div>
        <div className={`flex flex-col h-[140px] w-[35%] max-sm:min-w-full md:min-w-[35%] p-4 my-2 rounded-2xl items-end ${darkMode ? "hover:shadow-[0px_0px_4px_2px_rgba(0,_179,_255,_0.23)]" : "light-mode bg-white hover:shadow-[0px_0px_4px_2px_rgba(0,_179,_255,_0.23)]"}`}>
          <Link to={`/revenue`} className='font-semibold cursor-pointer hover:text-blue-500 hover:underline'>Revenue Chart</Link>
          <div className='text-[30px] font-bold text-slate-400'>Tzs <CountUp start={0} end={userData?.earningsTotalRevenue?.toLocaleString()} duration={2} /></div>
          <div className="flex flex-col-reverse w-full items-end">
            <div className='flex w-[50%] gap-2 justify-between'>
              <div>
                <div>
                  <div className='text-[14px] text-slate-600 font-semibold'><CountUp start={0} end={totalSeasons?.toLocaleString()} duration={2} /></div>
                  <div className='text-[10px] text-slate-400 font-bold'>Last month</div>
                </div>
              </div>
              <div>
                <div className='text-[14px] text-slate-600 font-semibold'>Tzs <CountUp start={0} end={userData?.earningsWithdrawedAmount?.toLocaleString()} duration={2} /></div>
                <div className='text-[10px] text-slate-400 font-bold'>Withdrawal</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=''>
        <div className=''>
          <div className='flex flex-wrap gap-2 m-4 justify-center items-center'>
            {create.map((item, index) => (
              <Link
                to={`${item.route}?type=${item.title.includes('Library') ? 'library' : 'user'}`}
                key={index}
                className='flex flex-col border shadow-[0px_0px_4px_2px_rgba(200,_300,_400,_0.50)] hover:shadow-[0px_0px_4px_2px_rgba(0,_179,_255,_0.23)] justify-center px-2 my-1 w-[280px] h-20 items-start rounded-lg mx-1 cursor-pointer'
              >
                <p className='font-bold text-[14px]'>{item.title}</p>
                <p className='text-[12px]'>{item.description}</p>
              </Link>
            ))}
          </div>
        </div>
      </div>
      <div className='w-full'>
        <div className='font-bold text-[18px]'>Statistics</div>
      </div>
      <div className={`flex ${!darkMode && 'bg-zinc-100'} mb-20 p-1 rounded-xl flex-row max-sm:flex-col flex-nowrap w-full md:pr-[80px] justify-start max-w-full min-w-0 gap-4 px-[6px]`}>
        <ActivityChart />
      </div>
    </div>
  );
};

const create = [
  {
    title: 'Register User',
    description: 'New user account',
    route: '/create/user'
  },
  {
    title: 'Register Library',
    description: 'New local library reseller account',
    route: '/create/user'
  },
];

export default AgentDashboard;