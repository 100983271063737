import { useContext, useState, useEffect } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebase";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import PropagateLoader from 'react-spinners/esm/PropagateLoader';
import { logo2 } from "../assets";
import CryptoJS from "crypto-js";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const secretKey = "8b364ea665d053603748ccdf2c181573adcac5c3f9a0f34860ac23a7f15f7c06";

const Login = () => {
  const [loading, setloading] = useState(false);
  const [error, setError] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();
  const { dispatch } = useContext(AuthContext);
  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(prev => !prev);
  };

  useEffect(() => {
    // Retrieve email and encrypted password from local storage if they exist
    const storedEmail = localStorage.getItem("email");
    const storedEncryptedPassword = localStorage.getItem("password");
    if (storedEmail && storedEncryptedPassword) {
      setEmail(storedEmail);
      // Decrypt the password
      const bytes = CryptoJS.AES.decrypt(storedEncryptedPassword, secretKey);
      const decryptedPassword = bytes.toString(CryptoJS.enc.Utf8);
      setPassword(decryptedPassword);
      setRememberMe(true);
    }
  }, []);

  const handleLogin = (e) => {
    setloading(true);
    setError(false);
    e.preventDefault();

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        console.log(user);

        // Save user authentication information in local storage
        localStorage.setItem("user", JSON.stringify(user));

        // Save email and encrypted password to local storage if "Remember me" is checked
        if (rememberMe) {
          const encryptedPassword = CryptoJS.AES.encrypt(password, secretKey).toString();
          localStorage.setItem("email", email);
          localStorage.setItem("password", encryptedPassword);
        } else {
          localStorage.removeItem("email");
          localStorage.removeItem("password");
        }

        // Dispatch login action to update authentication context
        dispatch({ type: "LOGIN", payload: user });

        const prevLocation = localStorage.getItem('prevLocation');
        if (prevLocation) {
          localStorage.removeItem('prevLocation');
          // Redirect the user back to the previous location
          navigate(prevLocation);
        } else {
          // If there is no previous location, redirect the user to the home page
          navigate('/');
        }
        setloading(false);
      })
      .catch((error) => {
        setloading(false);
        setError(true);
      });
  };

  return (
    <div className="h-screen flex flex-col items-center justify-center">
      <img src={logo2} className="w-[200px] max-md:w-[150px] shrink-0 m-10" alt="iTrood logo" />
      <form onSubmit={handleLogin} className="flex flex-col items-center">
        <div className="text-center font-bold text-slate-700 p-2">Sign In</div>
        <input
          type="email"
          placeholder="Email"
          required
          className="w-[350px] max-md:w-[200px] h-10 border border-gray-300 rounded-md px-2"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <div className="relative">
          <input
            type={passwordVisible ? "text" : "password"}
            placeholder="Password"
            required
            className="w-[350px] max-md:w-[200px] h-10 border border-gray-300 rounded-md px-2 mt-2 pr-10" // Adjust padding-right
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <div
            onClick={togglePasswordVisibility}
            className="absolute right-2 top-1/2 mt-1 transform -translate-y-1/2 cursor-pointer text-gray-500"
          >
            {passwordVisible ? <FaEyeSlash /> : <FaEye />}
          </div>
        </div>
        <div className="flex items-center mt-2">
          <input
            type="checkbox"
            id="rememberMe"
            checked={rememberMe}
            onChange={() => setRememberMe(!rememberMe)}
          />
          <label htmlFor="rememberMe" className="ml-2 text-sm text-slate-700">
            Remember me
          </label>
        </div>

        {loading ? (
          <div className="w-[350px] max-md:w-[200px] h-10 pb-4 flex bg-zinc-100 rounded-md mt-2 justify-center items-center">
            <PropagateLoader color="#3693d6" />
          </div>
        ) : (
          <button
            type="submit"
            className="w-[350px] max-md:w-[200px] h-10 bg-purple-500 text-white font-bold rounded-md mt-2 cursor-pointer"
          >
            Login
          </button>
        )}
        {error && <span className="text-red-500 text-sm mt-2">Wrong email or password!</span>}
      </form>
      <div className="text-center text-[14px] text-zinc-400 pt-4">Don't have an account yet?</div>
      <Link to="/register" className="text-center font-bold text-blue-500 pb-2 cursor-pointer">Create an Account</Link>
    </div>
  );
};

export default Login;